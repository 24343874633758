import React from "react";
// Customizable Area Start
import GoogleButton from "../../../components/src/SocialLogin/GoogleLogin";
// Customizable Area End

type Props = {
  testID: string;
  style: Object;
  onResponse: (credential: string) => void;
  onError: (error: string) => void;
  useOneTap: boolean;
  // Customizable Area Start
  // Customizable Area End
};

export default class CustomGoogleLogInButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <GoogleButton {...this.props} />
    );
    // Customizable Area End
  }
}
