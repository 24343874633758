import React from "react";
import { Event, View } from "react-big-calendar";
import Calendar from "./Calendar";
import "./Scheduler.css";

interface Props {
  error?: {
    message: string;
  };
  recievedView?: View;
  events: Event[];
  updateEvents: (events: Event[]) => void;
}

const StaffDayScheduler = React.memo(({ error, events, updateEvents, recievedView }: Props) => {
  return (
    <div className="scheduler__wrapper">
      <div className="scheduler__content">
        <Calendar recievedView={recievedView} error={error} events={events} updateEvents={updateEvents} />
      </div>
    </div>
  );
});

export { StaffDayScheduler };
