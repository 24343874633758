import React, { ChangeEvent, useEffect, useState } from "react";

import {
  // Customizable Area Start
  Button, Card, CardHeader, CardMedia, IconButton, Typography
  // Customizable Area End
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import "./../../blocks/customisableuserprofiles/src/Profile.css";
// Customizable Area Start
import {image_fav, image_veg} from "./../../blocks/favourites/src/assets";
import SimpleSnackbar from "./SnackBar";
import DialogPopup from "./DialogPopup.web";
import Share from "./../../blocks/share/src/Share.web";
import FilterPopup from "./../../blocks/catalogue/src/FilterPopup.web";
import {dietType} from "./../../blocks/catalogue/src/MenuData";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import ReactDOM from 'react-dom';
import { SpiceLevelTypes } from "../../blocks/ordermanagement/src/types";
// Customizable Area End
interface Props {
  navigateToPages: (page: string) => {}, 
  favourites: any,
  deleteFavorite: (id: number) => void,
  isVisible: boolean,
  hideModal: () => void,
  getFavourites: (token: string, filterUri?: string) => void,
  allergenLists: string[],
  navigateToMenu: (id:string,order_type:string,title:string,catalogue_id:string)=>void
  spiceLevel:SpiceLevelTypes[]
  getSpiceLevel:()=>void
}

export default function FavouriteList({navigateToPages, favourites, deleteFavorite, isVisible, hideModal, getFavourites,allergenLists,navigateToMenu,spiceLevel,getSpiceLevel}: Props) {
    const [open, setOpen] = React.useState(false);
    const [ openFilter, setOpenFilter ] = React.useState(false);
    const [spiceLevelList, setSpiceLevelList] = React.useState(spiceLevel);
    const [typeOfdiet, setTypeOfdiet] = React.useState(dietType);
    const [spiceLevelSelected, setSpiceLevelSelected] = React.useState<string | null>(null);
    const [dietSelected, setDietSelected] = React.useState<string | null>(null);
    const [allergenSelected, setAllergenSelected] = React.useState<string[]>([]);

    useEffect(() => {
      const rootElementContainer = document.createElement('div');
      rootElementContainer.id = 'root-element-container';
      document.body.appendChild(rootElementContainer);
      ReactDOM.render(<SimpleSnackbar isVisible={isVisible} handleClose={hideModal} />, document.getElementById('root-element-container'));
      return () => {
        const targetElement = document.getElementById('root-element-container');
        if (targetElement) {
          targetElement.remove();
        }
      }
    }, [isVisible])

    useEffect(()=>{
      getSpiceLevel()
    },[])

    const openClosePopup = (val: boolean) => {
        setOpen(val);
    }

    const handleFilterOpen = () => {
        setOpenFilter(true);
    }

    const handleFilterClose = () => {
        setOpenFilter(false);
    }

    const handleSpiceChange=(value:ChangeEvent<HTMLInputElement>):void=>{
        setSpiceLevelSelected(value.target.value)
      }

    const handleDietChange=(value:ChangeEvent<HTMLInputElement>):void=>{
        setDietSelected(value.target.value)
      }
    
    const handleAllergenChange=(value:string):void=>{
        const isAlreadySelected = allergenSelected.includes(value);
        
        if(isAlreadySelected){
          const updatedAllergens = allergenSelected.filter(item => item !== value);
          setAllergenSelected(updatedAllergens);
        }else{
          setAllergenSelected([...allergenSelected, value]);
        }
      }

    const handleApplyFilters=():void=>{
        const params: string[] = [];
        if (spiceLevelSelected != null) {
          if(spiceLevelSelected === 'Mango&Lime' ){
            params.push(`spice_level=Mango%26Lime`);
          }else{
            params.push(`spice_level=${spiceLevelSelected}`);
          }
          }
        
          // Check and add product_type key
          if (dietSelected != null) {
            params.push(`product_type=${dietSelected}`);
          }
        
          // Check and add allergens key
          if (allergenSelected.length > 0) {
            params.push(...allergenSelected.map(allergen => `allergens[]=${allergen}`));
          }

          const token = localStorage.getItem("authToken") || "";
          getFavourites(token, params.length ? `?${params.join('&')}` : '')
        setOpenFilter(false);
      }
    
    const handleClearFilters=():void=>{
        setSpiceLevelSelected(null);
        setAllergenSelected([]);
        setDietSelected(null);
        const token = localStorage.getItem("authToken") || "";
        getFavourites(token);
        setOpenFilter(false);
    }

    return (
    <>
      <div className="fav-menuContainer">
        <div className="favourite-filter">
            <div className="head-content">
                <Typography variant="h5" className="header" component="div">Favourites</Typography>
                {!!favourites.length && <Typography variant="h5" className="count" component="div">{favourites.length} {favourites.length > 1 ? "Items" : "Item"}</Typography>}
            </div>
            {(!!favourites.length || (!spiceLevelSelected || !dietSelected || !allergenSelected.length )) && <Button test-id='filter' onClick={()=> handleFilterOpen()} className="filter_btn">Filters <FilterListRoundedIcon style={{marginLeft:"6px"}}/></Button>}
        </div>
        <div data-test-key="all-favourite-card-container" className={`all-favourite-card-container ${favourites.length && "pad_top"}`}>
            {favourites.map((item: any) => (
                <Card data-test-key="1" key={item.id} className="favourites-menu-mainCard">
                    {item.attributes.images.url && <CardMedia style={{ borderRadius: '50%', height: "194px", width: "194px", marginTop: "-60px" }} component="img" image={item.attributes.images.url} alt={`${item.attributes.images.file_name}`} />}
                    <CardHeader
                        className="fav-card-header"
                        title={item.attributes.sub_sub_category.title}
                        action={
                            <div>
                                <IconButton onClick={() => deleteFavorite(item.id)} className="favourites_btn" aria-label="add to favorites">
                                    <FavoriteIcon />
                                </IconButton>
                                <IconButton onClick={() => openClosePopup(true)} className="share_btn" aria-label="share">
                                    <ShareIcon />
                                </IconButton>
                            </div>
                        }
                    />

                    {item.serve && <Typography variant="body2" style={{ marginTop: "4px", marginLeft: "16px", alignSelf: "flex-start" }}>{item.serve}</Typography>}
                    {item.attributes.on_its_own_price && <Typography variant="h5" component="div" className="fav-card-header price">{`£${item.attributes.on_its_own_price}`}</Typography>}
                    {item.attributes.product_type === "vegetarian" && (<div className="product-card-header">
                        <img src={image_veg} alt="veg" width="14px" height="14px" />
                        <Typography className="product_type" variant="h5" component="div">Vegetarian</Typography>
                    </div>)}
                    <Button variant="contained" className="favorites_add_cart" onClick={()=>navigateToMenu(`${item.attributes.restaurant.id}`,item.attributes.restaurant.order_type[0],item.attributes.restaurant.title,item.id)}>
                        ADD TO CART
                    </Button>
                </Card>
            ))}
          {!favourites.length && (
              <div style={{display: "flex", flexDirection: "column", gap: "10px", width: "100%"}}>
                  <div className="empty-info">
                      <Typography variant="h6" className="empty" component="div">Looks like your favorites plate is empty! </Typography>
                      <Typography variant="h6" className="add-info" component="div">Spice it up by adding some delicious dishes now.</Typography>
                  </div>
                  <img src={image_fav} alt="fav_image" style={{width: "326px", height: "326px", alignSelf: "center"}} />
                  <Button onClick={() => navigateToPages("MenuPage")} variant="contained" className="menu_btn">WINGO’s Menu</Button>
              </div>
          )}
      </div>
      <DialogPopup open={open} onClose={() => openClosePopup(false)}><Share navigation={null} id="" endPoint="/Menu"></Share></DialogPopup>
      <FilterPopup open={openFilter} onClose={handleFilterClose} allergen={allergenLists} spiceLevel={spiceLevel} dietType={typeOfdiet}
                spiceChange={handleSpiceChange}
                dietChange={handleDietChange}
                allergenChange={handleAllergenChange}
                selectedAllergen={allergenSelected}
                selectedSpice={spiceLevelSelected}
                selectedDiet={dietSelected}
                applyFilter={handleApplyFilters}
                clearFilter={handleClearFilters}/>
      </div>
    </>
      // Customizable Area End
    );
}

