import { SpiceLevelType } from "./MenuPageController";
import {spice1, spice3, spice2, bbq, lime, veg, plant_based, non_veg} from "./assets";

export const dietType :SpiceLevelType[] = [
  {
    "id": 1,
    "img": veg,
    "title": 'Vegetarian',
    "value": 'vegetarian'
  },
  {
    "id": 2,
    "img": plant_based,
    "title": 'Plant-based',
    "value":"plant_based"
  },
  {
    "id": 3,
    "img": non_veg,
    "title": 'Non-Vegetarian',
    "value":"non_vegetarian"
  },
]