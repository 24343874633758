import React from "react";
import { EventProps, EventWrapperProps } from "react-big-calendar";
import "./EventComponent.css";

const EventComponent = (props: EventProps) => {
  return (
    <div>
      <strong>{props.event.title}</strong>
    </div>
  );
};

const EventWrapper = (
  props: React.PropsWithChildren<EventWrapperProps<object>>
) => {
  return (
    <div className="event_wrapper">
      {props.children}
    </div>
  );
};

const EventContainerWrapper = (props: React.PropsWithChildren<{}>) => {
  return <div className="event_container_wrapper">{props.children}</div>;
};

export { EventComponent, EventWrapper, EventContainerWrapper };
