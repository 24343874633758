import React from "react";
import { CalendarTodayRounded } from "@material-ui/icons";
import { InputAdornment } from "@mui/material";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { WingoInput } from "../Inputs/CustomInputs";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css";

interface Props extends ReactDatePickerProps {};

const DateInput = (props: Props) => {
  const getDateFormat = () => {
    const { value } = props;

    if (value && value.includes("-")) {
      return "yyyy-MM-dd";
    }

    return "yyyy/MM/dd";
  };

  return (
    <DatePicker
      {...props}
      showIcon
      popperClassName="date_picker_popper"
      wrapperClassName="date_picker_wrapper"
      dateFormat={getDateFormat()}
      value={props.value}
      customInput={
        <WingoInput
          endAdornment={
            <InputAdornment position="end">
              <CalendarTodayRounded />
            </InputAdornment>
          }
        />
      }
    />
  )
};

export default DateInput;
