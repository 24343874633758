import React from "react";
import * as z from "zod";
import { FormControl, IconButton, TextField, FormLabel, FormHelperText } from "@mui/material";
import { CloseRounded } from "@material-ui/icons";
import Modal from "../../Modal";
import { ContainedButton, OutlinedButton } from "../../Button";
import "./ManageNote.css";

interface Props {
  edit: {
    id: number;
    note: string;
  } | null;
  id: string;
  open: boolean;
  onClose: () => void;
  submit: (id: string, note: string, note_id?: number) => void;
};

const maxCharacters = 50;

const ManageNote = ({ edit, id, open, onClose, submit }: Props) => {
  const isEdit = !!edit;

  const [note, setNote] = React.useState("");
  const [error, setError] = React.useState("");
  const [characters, setCharacters] = React.useState(0);

  React.useEffect(() => {
    setCharacters(note.length);
    const noteSchema = z.string().max(maxCharacters, {
      message: `This field should not exceed ${maxCharacters} characters. Please adjust the input.`,
    });
    const validationErrors = noteSchema.safeParse(note).error;

    if (validationErrors) {
      setError(validationErrors.errors[0].message);
    } else {
      setError("");
    }
  }, [note]);

  React.useEffect(() => {
    if (edit) {
      setNote(edit.note);
    } else {
      setNote("");
    }
  }, [edit]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className="manage_note__wrapper">
        <div className="manage_note__header">
          <span className="manage_note__title">{isEdit ? "Update note" : "Add notes"}</span>

          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </div>

        <div className="manage_note__content">
          <FormControl fullWidth>
            <FormLabel classes={{ root: "manage_note__label" }}>
              Notes
            </FormLabel>

            <TextField
              multiline
              rows={4}
              name="notes"
              placeholder="Start typing here..."
              value={note}
              onChange={(event) => setNote(event.target.value)}
              error={!!error}
              inputProps={{
                className: "manage_note__input",
              }}
              classes={{
                root: "manage_note__textfield",
              }}
            />
            <FormHelperText
              classes={{ root: "manage_note__helper_text" }}
              error={!!error}
            >
              <span className="manage_note__helper_text__error">{error}</span>
              <span>{characters}/{maxCharacters}</span>
            </FormHelperText>
          </FormControl>
        </div>

        <div className="manage_note__footer">
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!!error}
            onClick={() => submit(id, note, edit?.id)}>
            Save
          </ContainedButton>
        </div>
      </div>
    </Modal>
  );
};

export default ManageNote;
