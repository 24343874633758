import React from "react";
import {
    logo,
} from "../../../../blocks/dashboard/src/assets";
import LogoutPopup from "../../../../blocks/settings2/src/LogoutPopup.web";
import { chefRoutes, inStoreManagerRoutes, inStoreOperatorRoutes } from "./routes";
import "./Sidebar.css";

interface Props {
    navigation: any;
}

interface S {
    logOut: boolean;
}

export default class Sidebar extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props);

        this.state = {
            logOut: false
        }
    }

    handleNavigation = (href: string) => {
        location.href = href;
    }

    logout = () => {
        this.setState({ logOut: true });
    }

    renderNavbar = (role: string | null): {
        name: string;
        icon: any;
        href: string;
    }[] => {
        switch (role) {
            case "In Store Operator":
                return inStoreOperatorRoutes;
            case "In Store Manager":
                return inStoreManagerRoutes;
            case "Chef":
                return chefRoutes;
            default:
                return [];
        }
    }

    render() {
        const role = sessionStorage.getItem("role") ?? null;
        const navMenu = this.renderNavbar(role);

        const sidebarWrapper = document.querySelector(".sidebar__navbar");
        if (sidebarWrapper) {
            if (sidebarWrapper.scrollHeight > sidebarWrapper.clientHeight) {
                sidebarWrapper.classList.add("scrollbar__shadow");
            } else {
                sidebarWrapper.classList.remove("scrollbar__shadow");
            }
        }

        return (
            <div className="sidebar__wrapper">
                <div className="sidebar__logo_wrapper">
                    <img src={logo} alt="logo" className="sidebar__logo" />
                </div>

                <nav className="sidebar__navbar">
                    {navMenu.map((item, index) => {
                        const isActive = `${window.location.pathname}?role=${role}` === item.href;
                        return (
                            <div key={index} className="nav__item" onClick={() => this.handleNavigation(item.href)}>
                                {isActive && <div className="nav__active" />}
                                <img src={item.icon} alt="icon" className="nav__item__icon" />
                                <span className="nav__item__label">{item.name}</span>
                            </div>
                        )
                    })}
                </nav>

                <LogoutPopup open={this.state.logOut} onClose={() => this.setState({ logOut: false })} />
            </div>
        );
    }
}
