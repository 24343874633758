import React from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { StaffAttributes } from "../../../../../blocks/dashboard/src/dashboards/StaffInformation/StaffInformationController.web";

interface Props {
  actionCell: (info: CellContext<StaffAttributes, any>) => JSX.Element;
}

export const staffInformationColumns = ({actionCell}: Props) => {
    const columns: ColumnDef<StaffAttributes, any>[] = [
        {
            header: "ID",
            accessorKey: "staff_id",
            cell: info => info.getValue(),
        },
        {
            header: "Name",
            accessorKey: "full_name",
            cell: info => info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1),
        },
        {
            header: "Role",
            accessorKey: "user_roles",
            cell: info => info.getValue().map((role: any) => role.name).join(", "),
        },
        {
            header: "Email Address",
            accessorKey: "email",
            cell: info => info.getValue(),
        },
        {
            header: "Mobile Number",
            accessorKey: "full_phone_number",
            cell: info => info.getValue(),
        },
        {
            header: "Action",
            accessorKey: "action",
            cell: info => actionCell(info),
        },
    ];

    return columns;
}
