import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  Dialog,
  SvgIcon
} from "@mui/material";
import { handleStoreNavigation } from "../../../components/src/utils";
import "./Rolesandpermissions2.web.css";
// Customizable Area End

import Rolesandpermissions2Controller, {
  Props,
  configJSON,
} from "./Rolesandpermissions2Controller";
import { CloseRounded } from "@material-ui/icons";
import { rolesAndPermissonsBanner } from "./assets";

export default class Rolesandpermissions2 extends Rolesandpermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  pathname = window.location.pathname;

  handleChange = (value: string): void => {
    this.setState({ selectedPOS: value });
  }

  handleCloseDialog = (_event: object, reason: string): void => {
    if (reason === "backdropClick") return;
    else this.setState({ openDialog: false });
  }

  handleSubmit = () => {
    handleStoreNavigation(this.state.selectedPOS, this.props.navigation);
    this.setState({ openDialog: false });
  }

  renderContent = (): JSX.Element => {
    const options: { role: string; icon: any; href: string }[] = this.rolesGroup.filter((roleObj: any) => this.state.roles.includes(roleObj.role));

    return (
      <Box className="roles__box">
        <div className="roles__header">
          <img src={rolesAndPermissonsBanner} alt="banner" className="roles__banner" />

          <h1 className="roles__title">
            Welcome {this.state.userDetail?.first_name}!
          </h1>

          <p className="roles__description">
            Please select your role to proceed further
          </p>
        </div>

        <div className="roles__list">
          {options.map((option, index) => {
            const active = this.state.selectedPOS === option.role;
            return (
              <div
                data-testid="roles-item"
                key={index}
                onClick={() => this.handleChange(option.role)}
                className="roles__item"
                data-active={active}>
                <SvgIcon
                  data-active={active}
                  classes={{ root: "roles__item_icon" }}
                >{option.icon}</SvgIcon>
                <p data-active={active} className="roles__item_title">{option.role}</p>
              </div>
            );
          })}
        </div>

        <Button
          data-testid="roles-button"
          disabled={!this.state.selectedPOS}
          variant="contained"
          onClick={this.handleSubmit}
          classes={{ root: "roles__button", label: "roles__button_label" }}
        >
          Proceed
        </Button>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      this.pathname === "/Rolesandpermissions2" ? (
        <div data-testid="roles-wrapper" className="roles_wrapper">
          <Container maxWidth="sm">
            {this.renderContent()}
          </Container>
        </div>
      ) : (
        <Dialog
          data-testid="roles-dialog"
          fullWidth
          maxWidth="sm"
          scroll="body"
          classes={{ paper: "roles__dialog" }}
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}>
          <IconButton
            data-testid="roles-dialog-close"
            size="small"
            onClick={this.handleSubmit}
            classes={{ root: "roles__dialog_close" }}>
            <CloseRounded className="roles__dialog_close__icon" />
          </IconButton>
          {this.renderContent()}
        </Dialog>
      )
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
