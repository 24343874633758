import React from "react";
import { View } from "react-native";
import { useGoogleLogin } from "@react-oauth/google";
import { googleIcon } from "../../../blocks/social-media-account/src/assets";

const configJSON = require("../../../blocks/social-media-account/src/config");

type Props = {
    testID: string;
    style: Object;
    onResponse: (credential: string) => void;
    onError: (error: string) => void;
    useOneTap: boolean;
};

const GoogleButton = (props: Props) => {
    const login = useGoogleLogin({
        onSuccess(tokenResponse) {
            tokenResponse.access_token && props.onResponse(tokenResponse.access_token);
        },
        onError(error) {
            props.onError(configJSON.loginFailed);
        },
    });

    return (
        <View
            style={props.style}
            testID={props.testID || "googleLogin"}>
            <img
                src={googleIcon}
                alt="Google Icon"
                onClick={() => login()} style={{ cursor: "pointer" }} />
        </View>
    );
}

export default GoogleButton;