import { Chip, styled } from "@mui/material";

export const WingosChip = styled(Chip)(({ theme }) => ({
  borderRadius: 8,

  ".MuiChip-label": {
    fontSize: 14,
    fontFamily: ["Barmeno", "sans-serif"].join(","),
    color: "#1D1D1D",
  },

  ".MuiChip-deleteIcon": {
    width: 16,
    height: 16,
    color: "#1D1D1D",
  },
}));
