import React from "react";
import { IconButton } from "@mui/material";
import { ToolbarProps } from "react-big-calendar";
import { CalendarOutlined, ChevronLeft, ChevronRight, WarningAmberOutlined } from "../../../../Icons";
import "./Toolbar.css";

interface Props extends ToolbarProps {
  error?: {
    message: string;
  };
}

const Toolbar = ({ error, ...props }: Props) => {
  const { label, onNavigate, views, view: activeView, onView } = props;

  const getViews = () => {
    if (typeof views === "object") {
      return Object.values(views);
    } else {
      return views;
    };
  };

  return (
    <div className="scheduler__header">
        <div className="scheduler__header__left">
          <div className="scheduler__header__left__title">
            <span>{label}</span>
          </div>
          {error && (
            <div className="scheduler__header__left__error">
              <span>{error.message}</span>
              <WarningAmberOutlined />
            </div>
          )}
        </div>

        <div className="scheduler__header__right">
          <div className="scheduler__header__right__button_group">
            <IconButton onClick={() => onNavigate("PREV")}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={() => onNavigate("NEXT")}>
              <ChevronRight />
            </IconButton>
          </div>

          <IconButton onClick={() => onNavigate("TODAY")}>
            <CalendarOutlined />
          </IconButton>

          <div className="scheduler__header__right__tab">
            {getViews().map((view) => (
              <div
                key={view}
                data-active={view === activeView}
                className="scheduler_tab"
                onClick={() => onView(view)}
              >
                {view}
              </div>
            ))}
          </div>
        </div>
      </div>
  )
};

export default Toolbar;
