import React from "react";
import { IconButton } from "@mui/material";
import { CloseRounded } from "@material-ui/icons";
import "./Modal.css";

interface Props {
  onClose?: any;
}

const CloseButton = ({ onClose }: Props) => {
  return (
    <IconButton onClick={onClose} className="wrapper__close_button">
      <CloseRounded />
    </IconButton>
  )
}

export default CloseButton;
