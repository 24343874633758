import React from "react";
// Customizable Area Start
import { StyleSheet, Dimensions, Platform } from "react-native";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer
} from '@react-google-maps/api';
import { anchor, MapsMap, origin, scaledSize } from "../../../components/src/MapsDistanceRouting";
import { currentLocation, driverLocation } from "./assets";
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  // Customizable Area End

  render() {
    // Customizable Area Start
    const customStartIcon = {
      url: driverLocation
    };
    const customEndIcon = {
      url: currentLocation, scaledSize,
      origin,
      anchor
    };
    return (
      <div style={{ height: '500px', width: '100%' }}>
        {console.log("destination", this.state.destination, this.state.origin)}
        <GoogleMap
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map: MapsMap) => this.setState({ map })}
        >
          {this.state.directionsResponse && (
            <>
            <Marker icon={customStartIcon} position={this.state.directionsResponse.routes[0].legs[0].start_location}/>
            <Marker position={this.state.directionsResponse.routes[0].legs[0].end_location} icon={customEndIcon}/>
            <DirectionsRenderer directions={this.state.directionsResponse}  options={{ suppressMarkers: true,  polylineOptions: {strokeColor: '#CD191F',strokeWeight: 4, strokeOpacity: 1.0 }}} />
            </>
          )}
        </GoogleMap>
    
  </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  map: {
    width: viewportWidth,
    height: viewportHeight,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
});
// Customizable Area End
