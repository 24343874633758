import React from "react";
import { OutlinedButton } from "../../../Button";
import { Accordion, AccordionDetails, AccordionSummary } from "../../../Accordian";
import { StaffDayScheduler } from "../../Scheduler";
import "./StaffSchedule.css";
import { Event } from "react-big-calendar";

interface Props {
  staff: {
    Chef: any[];
    "Online Order Manager": any[];
    "In Store Operator": any[];
    "Rider/Driver": any[];
    "In Store Manager": any[];
  },
  error?: {
    message: string;
  };
  events: Event[];
  updateEvents: (events: Event[]) => void;
};

const StaffSchedule = ({ staff, error, events, updateEvents }: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [selectedStaff, setSelectedStaff] = React.useState<number | null>(null);

  const timeLabels = [
    { title: "Scheduled Time", color: "#F59E0B" },
    { title: "Logged Time", color: "#2C6F37" },
    { title: "Null", color: "#A2A2A2" }
  ];

  const sidebar = [
    { label: "Schedule" },
    { label: "Chef", options: staff["Chef"] },
    { label: "Delivery Rider", options: staff["Rider/Driver"] },
    { label: "Till Operator", options: staff["In Store Operator"] },
  ];

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <section className="staff_schedule__wrapper">
      <div className="staff_schedule__toolbar">
        {timeLabels.map((label, index) => (
          <div
            key={index}
            className="staff_schedule__toolbar__item"
          >
            <div
              className="staff_schedule__toolbar__item__circle"
              style={{ backgroundColor: label.color }}
            />
            <span className="staff_schedule__toolbar__item__label">{label.title}</span>
          </div>
        ))}

        <OutlinedButton className="staff_schedule__toolbar__button">Time Off</OutlinedButton>
      </div>

      <div className="staff_schedule__scheduler">
        <div className="staff_schedule__staff_list">
          {sidebar.map((item, itemIdx) => (
            <Accordion
              key={itemIdx}
              expanded={expanded === item.label}
              onChange={handleChange(item.label)}>
              <AccordionSummary>{item.label}</AccordionSummary>
              {item.options && item.options.map((option) => (
                <AccordionDetails
                  key={option.id}
                  className={`staff_schedule__staff_list__option ${selectedStaff === option.id ? "selected" : ""}`}
                  onClick={() => setSelectedStaff(option.id)}
                >
                  <div className="staff_schedule__staff_list__option__index">
                    {option.id}
                  </div>
                  <span className="staff_schedule__staff_list__option__label">
                    {option.label}
                  </span>
                </AccordionDetails>
              ))}
            </Accordion>
          ))}
        </div>
        {/* Scheduler */}
        <StaffDayScheduler
          recievedView={selectedStaff ? "week" : undefined}
          error={error}
          events={events}
          updateEvents={updateEvents}
        />
      </div>
    </section>
  );
};

export default StaffSchedule;
