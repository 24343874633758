import { InputBase, styled } from "@mui/material";

export const UnstyledInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "transparent",
    color: "#747474",
    border: "none",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    fontFamily: ["Barmeno", "sans-serif"].join(","),
    "&:focus": {
      color: "#1d1d1d",
    },
    "&::hover": {
      color: "#1d1d1d",
    },
  },
}));

export const BaseInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "transparent",
    color: "#747474",
    border: "none",
    fontSize: 16,
    padding: "0",
    fontFamily: ["Barmeno", "sans-serif"].join(","),
    "&:focus": {
      color: "#1d1d1d",
    },
    "&::hover": {
      color: "#1d1d1d",
    },
  },
}));

export const WingoInput = styled(InputBase)(({ theme }) => ({
  "&.MuiInputBase-adornedEnd > .MuiIconButton-root": {
    position: "absolute",
    right: 4,
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "#FFFFFF",
    color: "#1D1D1D",
    border: "1px solid #1D1D1D",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 400,
    padding: "10px 8px",
    fontFamily: ["Barmeno", "sans-serif"].join(","),
    "&:focus": {
      color: "#1d1d1d",
    },
    "&::hover": {
      color: "#1d1d1d",
    },
    "&::placeholder": {
      color: "#A2A2A2",
      fontWeight: 400,
    },
    "&.MuiInputBase-inputAdornedEnd": {
      padding: "10px 44px 10px 8px",

      "& .MuiInputAdornment-root": {
        position: "absolute",
        right: 8,
      },
    },
  },
  "& .Mui-disabled": {
    cursor: "not-allowed",

    "& .MuiInputBase-input": {
      backgroundColor: "#F1F1F1",
      border: "1px solid #F1F1F1",
    }
  },
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: "1px solid #E42328",
    }
  },
}));
