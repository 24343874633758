import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Link,
  Badge,
  Grid,
} from "@material-ui/core";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { recallInterval } from "./../../../components/src/utils"
import NotificationDialog from "../../notifications/src/NotificationDialog.web";
import { logo, cartIcon, bellIcon, buttonProfile, buttonMenu, buttonCross } from "./assets";
import './TopNavigation.css';

interface TopNavigationProps {
  id: string;
  navigation: any;
  onMenuClick: () => void;
  openLogin: (navigator: string) => void;
  drawerOpen: boolean;
  handlePathname:()=>string;
  isLoggedIn?: boolean;
  cartCount: number;
  notificationCount: number;
}

export default function TopNavigation({ id, navigation, onMenuClick, drawerOpen, openLogin, handlePathname, isLoggedIn, cartCount, notificationCount }: TopNavigationProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeSection, setActiveSection] = React.useState("join_now");

  const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationAnchorEl(event?.currentTarget);
  }

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = async (event:DOMStringMap) => {
    const {scrollId} = event;
    setActiveSection(scrollId || "join_now")  
    if(handlePathname() != "/WingoRewards") {
      openLogin("/WingoRewards");
      recallInterval(scrollId as string);
    } else {
      const selectedId: any = document.getElementById(scrollId as string);
      selectedId.scrollIntoView({
        block:'center',
        behavior: 'smooth',
      })
    } 
  };

  const handleCartItemClick = () => {
    if (cartCount > 0) {
      openLogin("/CartOrderItem");
    } else {
      openLogin("/Menu");
    }
  }

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar className={`${classes.toolbar} toolbar`}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{flexWrap: "nowrap"}}
        >
          <Grid item lg={8} xs={12} className={`${classes.gridItem} grid_items`}>
            <Link className={`img_link`} underline="none" onClick={() => openLogin("/")}>
              <img alt="logo" src={logo} className={classes.logo} />
            </Link>
            <Link className={handlePathname() == "/Menu" ? 'red-heading' : ''} onClick={() => openLogin("/Menu")} underline="none" style={{cursor:'pointer'}}>
              Menu
            </Link>
            <Link className={handlePathname() == "/Deals" ? 'red-heading' : ''} onClick={() => openLogin("/Deals")} underline="none" style={{cursor:'pointer'}}>
              Deals
            </Link>
            <Link className={handlePathname() == "/Restaurant" ? 'red-heading' : ''} underline="none" onClick={() => openLogin("/Restaurant")} style={{cursor:'pointer'}}>
              Restaurants
            </Link>
            <Button
              data-test-id="menu-btn"
              aria-controls="simple-menu"
              aria-haspopup="true"
              id="rewardBtn"
              className={(handlePathname() == "/WingoRewards") ? 'red-heading' : 'dropdown-button'}
              onClick={handleMenuClick}
            >
              Wingo’s Rewards
              <ExpandMoreIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem className={(handlePathname() == "/WingoRewards" && activeSection == "join_now") ? 'menu_item' : ''} data-scroll-id="join_now" data-test-id="menu-item" onClick={(event) => {handleMenuClose(); handleScroll(event.currentTarget.dataset)}} style={{cursor:'pointer', fontFamily:"Barmeno"}}>
                {`${isLoggedIn ? "Wingo's Rewards" : 'Join now' }`}
              </MenuItem>
              <MenuItem className={(handlePathname() == "/WingoRewards" && activeSection == "how_it_works") ? 'menu_item' : ''} data-scroll-id="how_it_works" onClick={(event) => {handleMenuClose(); handleScroll(event.currentTarget.dataset)}} style={{cursor:'pointer', fontFamily:"Barmeno"}}>
                How it works
              </MenuItem>
            </Menu>
            <Link className={handlePathname() == "/AboutUs" ? 'red-heading' : ''} onClick={() => openLogin("/AboutUs")} underline="none" style={{cursor:'pointer'}}>
              About Us
            </Link>
            <Link className={handlePathname() == "/Faqs" ? 'red-heading' : ''} onClick={() => openLogin("/Faqs")} underline="none" style={{cursor:'pointer'}}>
              Help
            </Link>
          </Grid>
          <Grid
            item
            lg={4}
            xs={12}
            className={`${classes.gridItem} ${classes.secondGridItem} secondGridItem`}
          >
            <IconButton
              data-testId="cart-button"
              className="icon_img"
              aria-label="delete"
              onClick={handleCartItemClick}>
              <Badge data-testId="cart-badge" invisible={cartCount === 0} badgeContent={cartCount} color="primary" className={`${classes.badge} ${classes.cartBadge}`}>
                <img src={cartIcon} style={{height:"48px",aspectRatio:"1"}} alt="cart icon" />
              </Badge>
            </IconButton>
            <IconButton data-testId="notification-button" disabled={!isLoggedIn} className="icon_img" aria-label="delete" onClick={handleNotificationClick}>
              <Badge data-testId="notification-badge" invisible={notificationCount === 0} badgeContent={notificationCount} color="primary" className={`${classes.badge} ${classes.notificationBadge}`}>
                <img src={bellIcon} style={{height:"32px",aspectRatio:"1"}} alt="notification bell icon" />
              </Badge>
            </IconButton>
            <NotificationDialog data-testId="notification-dialog" id={id} navigation={navigation} navigate={openLogin} anchorEl={notificationAnchorEl} handleClose={handleNotificationClose} />
            {isLoggedIn ? (<IconButton  data-test-id="login-btn" className="profile_icon" aria-label="profile" onClick={() => openLogin("/Profile")}>
                <img src={buttonProfile} style={{width:"48px",aspectRatio:"1"}} alt="button profile" />
            </IconButton>):
             (<Link className="signInBtn" onClick={() => openLogin("/accountlogin")} underline="none"><Button className={classes.signInBtn} startIcon={<PersonOutlineIcon />}>Sign In</Button></Link> )}
            <Button variant="contained" className={`${classes.primaryBtn} order_btn`} onClick={() => openLogin("/Menu")}>
              Order Online
            </Button>
            <IconButton className="menu_icon" aria-label="menu" onClick={onMenuClick}>
                {drawerOpen ? (<img src={buttonCross} alt="cross icon" />) : (<img style={{height:"56px",aspectRatio:"1"}} src={buttonMenu} alt="menu icon" />)}
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appbar: {
      borderBottom: "1px solid #E2E8F0",
      zIndex: theme.zIndex.drawer + 1,
      background: "transparent",
      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    logo: { height: "50px", width: "140px" },
    profileIcon: {
      backgroundColor: "#409046",
    },
    gridItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "24px"
    },
    secondGridItem: {
      flexDirection: "row",
      paddingLeft: 0,
      gap: "24px",
      justifyContent: "flex-end"
    },
    menuBtn: {
      paddingLeft: 0,
      fontSize:"14px",
      "& .MuiListItem-button": {
        color: "red",
      },
    },
    signInBtn: {
      textTransform: "capitalize",
      color: "#313131",
      fontFamily: "Barmeno",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "16px",
    },
    primaryBtn: {
      width: "200px",
      height: "56px",
      padding: "16px",
      borderRadius: "8px",
      background: "#E11B23",
      color: "#FFF",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    notificationBadge: {
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "5px",
        right: "5px",
      },
    },
    cartBadge: {
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "8px",
        right: "10px",
      },
    },
    badge: {
      "& .MuiBadge-colorPrimary": {
        backgroundColor: "#409046",
      },
    },
  })
);