import { IconButton, styled } from "@mui/material";

export const OutlinedIconButton =  styled(IconButton)({
  border: "1px solid #E11B22 !important",
  borderRadius: "8px !important",
  width: "48px",
  height: "48px",
  backgroundColor: "#fff !important",
  color: "#E11B22 !important"
});

export const ContainedIconButton = styled(IconButton)({
  borderRadius: "8px !important",
  width: "48px",
  height: "48px",
  backgroundColor: "#E11B22 !important",
  color: "#fff !important",
  "&:hover": {
    backgroundColor: "#E11B22 !important",
    opacity: 0.8
  },
  "&:disabled": {
    backgroundColor: "#F1F1F1 !important",
    color: "#909090 !important",
  },
});
