import React from 'react';
import { Typography } from '@mui/material';
import { pickup, invoice_btn, delivery } from "./../SocialLogin/assets";
import "./ViewOrderDetails.web.css";
import ScheduleIcon from "@material-ui/icons/Schedule"
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded"
import { storeFront } from '../assets';
import { allergenIcons, messageCart } from '../../../blocks/shoppingcart/src/assets';
import { OrderHistoryDetails } from '../../../blocks/ordermanagement/src/OrderHistoryController';
import GetCardBrand from '../ConfirmOrder/GetCardBrand.web';
import { showDips } from '../utils';
interface Props {
    OrderDetails:null|OrderHistoryDetails
    formatDate:(date:string)=>string
    getInvoiceDataDownload:(id:string)=>void
}

const ViewOrderDetails: React.FC<Props> = ({OrderDetails,formatDate,getInvoiceDataDownload}) => {
    const showSpiceLevel = (item: any): boolean => {
        if (item.attributes.spice_level && item.attributes.spice_level !== 'none') return true;
        return false;
    }

    return (<>
    {OrderDetails &&
        <div className='order_details_view'>
            <div className='title'>
                <img src={OrderDetails.attributes.order_type === "delivery"? delivery :pickup} alt='pickup' />
                <Typography className='order_id'>Order Details {(OrderDetails.attributes.order_number)}</Typography>
            </div>
            <div style={{justifyContent:OrderDetails.attributes.collected_at || OrderDetails.attributes.delivered_at ? 'space-between': "right" }} className='date_download'>
                {OrderDetails.attributes.collected_at ? <Typography className='date_time'>Collected on {formatDate(OrderDetails.attributes.collected_at||"")}</Typography>:
                OrderDetails.attributes.delivered_at ?<Typography className='date_time'>Delivered on {formatDate(OrderDetails.attributes.delivered_at||"")}</Typography>:<></>}
                <div onClick={()=>{getInvoiceDataDownload(OrderDetails.id)}} className='button_download'>
                    <Typography className='invoice'>Invoice</Typography>
                    <img src={invoice_btn} alt='invoice_btn' />
                </div>
            </div>
            <div className='payment-info-container' >
               <div className='order_payment_container'>
                <div className='order_payment_container_key'><ScheduleIcon style={{color:"#1D1D1D"}}  className="location"/>Ordered on </div> <div> {formatDate(OrderDetails.attributes.placed_at)}</div>
                </div>
                <div className='order_payment_container'>
                <div className='order_payment_container_key'><CurrencyPoundIcon  style={{color:"#1D1D1D",border:"2px solid #1D1D1D", borderRadius:"50%",width:"20px", height:"20px"}}  className="location"/>Payment </div> {OrderDetails.attributes.payment_transaction?<GetCardBrand brand={OrderDetails.attributes.payment_transaction.payment_method_type} />:<div>Cash</div>}
                </div>
                <div className='order_payment_container' style={{height:"100%",padding:"16px 12px"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"4px", alignItems:"flex-start"}}>
                <div style={{color:"#747474",fontSize:"14px"}} className='order_payment_container_key'><img src={storeFront} alt='store_front'/> <span style={{color:"#E11B22"}}>{OrderDetails.attributes.restaurant.title} -</span> {OrderDetails.attributes.restaurant.location} </div>
                {OrderDetails.attributes.delivery_addresses.length>0 && <div className='continue-dots'>......</div>}
                {OrderDetails.attributes.delivery_addresses.length>0 && 
                <div style={{color:"#1D1D1D",fontSize:"14px"}} className='order_payment_container_key' ><LocationOnRoundedIcon style={{color:"#1D1D1D"}}  className="location"/>{OrderDetails.attributes.delivery_addresses[0].address}, {OrderDetails.attributes.delivery_addresses[0].city}, {OrderDetails.attributes.delivery_addresses[0].zip_code}</div>}
                </div>
                </div>
                {(OrderDetails.attributes.delivered_at || OrderDetails.attributes.collected_at) &&<div style={{backgroundColor:"#F8FAFC"}} className='order_payment_container'><div className='order_payment_container_key'><img src={messageCart} style={{height:"24px",width:"24px"}}/>Earned {parseInt(OrderDetails.attributes.total).toFixed(0)} reward points </div></div>}
                </div>
                <div className='order_information_container'>
                <div>
                    <div className='order_information_title'>{OrderDetails.attributes.items_count} items</div>

                    <div style={{display:"flex",flexDirection:"column"}}>
                        {OrderDetails.attributes.items_detail.data.map((value,index)=>
                         <div className='order_items_information_quantity'> <div style={{display:"flex",gap:"12px"}}>{value.attributes.quantity}x 
                                <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}> {value.attributes.product_name} <div>{`${showSpiceLevel(value) ? value.attributes.spice_level.replace('_', ' ') : ""}`}{(value.attributes.sides.length > 0 || value.attributes.drinks.length > 0 || value.attributes.nibbles.length > 0) && ' | '}{value.attributes.sides.map((value) => `${value.name}, `)}{value.attributes.drinks.map((value) => `${value.name}, `)}{value.attributes.nibbles.map((value) => `${value.name}, `)}</div>
                                    {showDips(value) && (
                                        <span>Dips:{' '}
                                            <span>
                                                {showDips(value)}
                                            </span>
                                        </span>
                                    )}
                        {value.attributes.allergen_excluded &&  <div className="allergen_exclusion_info"> <img src={allergenIcons} alt="allergen_icons" style={{width:"16px", height:"16px"}} /> <span style={{display:"flex", height:"16px"}} > {value.attributes.allergen_excluded}</span></div>}
                         </div></div><div style={{color:"#1D1D1D", fontWeight:700, fontSize:"16px"}}>£{value.attributes.price}</div></div>
                        )}
                    </div>
                </div>

                <div className='order_summary_amount'>
                    <div className='order_summary_amount_key'> <div style={{color:"#747474"}} >Sub Total</div> <div style={{color:"#1D1D1D"}}>£{OrderDetails.attributes.sub_total}</div></div>
                    <div className='order_summary_amount_key'> <div style={{color:"#747474"}} >Coupons / Discounts</div> <div style={{color:"#1D1D1D"}}>£{OrderDetails.attributes.applied_discount}</div></div>
                    <div className='order_summary_amount_key'> <div style={{color:"#747474"}} >Redeem Points</div> <div style={{color:"#1D1D1D"}}>£{OrderDetails.attributes.points_worth}</div></div>
                    <div className='order_summary_amount_key'> <div style={{color:"#747474"}} >e-gift Card</div> <div style={{color:"#1D1D1D"}}>£{OrderDetails.attributes.gift_card_amount}</div></div>
                    {/* <div className='order_summary_amount_key'> <div style={{color:"#747474"}} >Delivery Charges</div> <div style={{color:"#1D1D1D"}}>£10</div></div> */}
                    {/* <div className='order_summary_amount_key'> <div style={{color:"#747474"}} >Sub Total</div> <div style={{color:"#1D1D1D"}}>£14.99</div></div> */}
                    <div className='order_summary_amount_key' style={{height:"54px",borderTop: "1px solid #D4D4D4"}} > <div style={{color:"#1D1D1D"}} >Total Amount</div> <div style={{color:"#1D1D1D"}}>£{OrderDetails.attributes.total}</div></div>
                </div>
                </div>
        </div>}</>
    )
}

export default ViewOrderDetails;