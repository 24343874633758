import React from "react";
import { Chip as MuiChip, ChipProps, SvgIcon } from "@mui/material";
import "./Chip.css";

interface Props extends ChipProps {}

const Chip = (props: Props) => {
    return (
        <MuiChip
            data-testid="chip"
            {...props}
            classes={{
                root: "chip__root",
                icon: "chip__icon",
                label: "chip__label",
            }}
        />
    );
};

export default Chip;