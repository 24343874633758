import React from "react";
import { HeaderProps } from "react-big-calendar";
import "./Month.css";

const Header = (props: HeaderProps) => {
  return (
    <div className="month_view__header">{props.label}</div>
  );
};

export { Header };
