export const menuBanner = require("../assets/menu_banner.png")
export const view3 = require("../assets/view_green.png")
export const view2= require("../assets/view_purple.png")
export const view1 = require("../assets/view_yellow.png")
export const chickenPicture = require("../assets/chicken.png")
export const FilletPicture = require("../assets/Fillet.png")
export const BurgerPicture = require("../assets/Burger.png")
export const dealBanner = require("../assets/Deals_Banner.png")
export const deal1 = require("../assets/deal1.png")
export const deal2 = require("../assets/deal2.png")
export const deal3 = require("../assets/deal3.png")
export const deal4 = require("../assets/deal4.png")
export const spice1 = require("../assets/image_spice1.png")
export const spice2 = require("../assets/image_spice2.png")
export const spice3 = require("../assets/image_spice3.png")
export const lime = require("../assets/image_lime.png")
export const bbq = require("../assets/image_bbq.png")
export const veg = require("../assets/image_veg.png")
export const non_veg = require("../assets/non_veg.svg")
export const plant_based = require("../assets/image_plant.png")
export const delivery_icon = require("../assets/delivery.png")
export const take_away_icon = require("../assets/take_away.png")
export const noResult = require("../assets/no_result_found.png")