import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { enterFullscreen, exitFullscreen } from "../../../../../components/src/utils";
import { Order } from "../types";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
};

export type Filters = {
  category: string;
  orderType: string | null;
};

interface S {
  token: string;
  selectedTab: number;
  ordersInCooking: Order[];
  ordersinQueue: Order[];
  currentPage: number;
  totalPages: number;
  openFilters: boolean;
  filters: Filters;
  isFullscreen: boolean;
};

interface SS {};

export default class ChefDashboardController extends BlockComponent<Props, S, SS> implements IBlock {
  orderListCallId: string = "";
  elementRef = React.createRef<HTMLDivElement>();

  categoryOptions = [
    { value: "none", label: "All" },
    { value: "breakfast", label: "Breakfast" },
    { value: "lunch", label: "Lunch" },
    { value: "dinner", label: "Dinner" },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      selectedTab: 0,
      ordersInCooking: [],
      ordersinQueue: [],
      currentPage: 1,
      totalPages: 1,
      openFilters: false,
      filters: {
        category: "none",
        orderType: null,
      },
      isFullscreen: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  };

  async componentDidMount() {
    super.componentDidMount();

    await this.getToken();
    this.getOrderList();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const handler = this.getMessageHandler(callId, response);
    if (handler) handler();
    if (error) runEngine.debugLog("API Error", error);
  }

  getMessageHandler(callId: string, response: any) {
    const messageHandlers = {
      [this.orderListCallId]: () => this.handleOrderList(response),
    };

    return messageHandlers[callId];
  };

  getOrderList = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.orderListCallId = getDataMsg.messageId;
    const filters = this.state.filters;
    const page = `?page=${this.state.currentPage}`;
    const orderType = filters.orderType ? `&order_type=${filters.orderType}` : "";
    const category = filters.category !== "none" ? `&category=${filters.category}` : "";
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chefDashboardAPI.endPoint + page + orderType + category
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getToken = async () => {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  };

  handleOrderList = (response: any) => {
    this.setState({
      ordersInCooking: response.orders_in_cooking,
      ordersinQueue: response.orders_in_queue,
      currentPage: parseInt(response.pagination.current_page),
      totalPages: response.pagination.total_pages,
    });
  };

  handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  handlePreviousPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 },
      this.getOrderList
    );
  };

  handleNextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 },
      this.getOrderList
    );
  };

  handleOpenFilters = () => {
    this.setState({ openFilters: true });
  };

  handleCloseFilters = () => {
    this.setState({ openFilters: false });
  };

  handleCategoryChange = (event: SelectChangeEvent<string>, _child: React.ReactNode) => {
    const filters = { ...this.state.filters, category: event.target.value };
    this.setState({ filters });
  };

  handleOrderTypeChange = (checked: boolean, value: string) => {
    const filters = { ...this.state.filters, orderType: checked ? value : null };
    this.setState({ filters });
  };

  applyFilter = () => {
    this.setState({ currentPage: 1, openFilters: false },
      this.getOrderList
    );
  };

  handleEnterFullscreen = () => {
    if (this.elementRef.current) {
      enterFullscreen(this.elementRef.current);
      this.setState({
        isFullscreen: true
      });
    }
  };

  handleExitFullscreen = () => {
    exitFullscreen();
    this.setState({
      isFullscreen: false
    });
  };
};
