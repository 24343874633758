import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  SvgIcon,
  Tab,
  Tabs,
} from "@mui/material";
import { WingoInput } from "../../Inputs/CustomInputs";
import { WingosDropzone, WingosPreview } from "../../Dropzone";
import CustomGreenCheckbox from "../../CustomGreenCheckBox.web";
import { WingosChip } from "../../Chip";
import { ReceivedFile } from "../../../../blocks/dashboard/src/dashboards/StaffInformation/StaffInformationController.web";
import Modal from "../../Modal";
import PhoneInputComponent from "../../PhoneInput";
import DateInput from "../../DateTimePicker";
import "./AddNewStaff.css";
import { HidePassword, ShowPassword } from "../../Icons";

type FormData = {
  roles: number[];
  firstName: string;
  lastName: string;
  countryCode: string;
  phoneNumber: string;
  identityType: string;
  identityDocument: File[];
  postCode: string;
  permitRequired: boolean;
  visaDocument: File[];
  expiryDate: string;
  email: string;
  password: string;
  confirmPassword: string;
};

interface Props {
  open: boolean;
  onClose: () => void;
  staffId: string;
  identityTypes: { id: number; identity_type: string }[];
  roles: { id: number; name: string }[];
  addStaff: (data: any) => { success: boolean, tab?: number };
  errors?: string[];
  validationErrors: Record<string, string>;
}

const defaultFormData: FormData = {
  roles: [],
  firstName: "",
  lastName: "",
  countryCode: "44",
  phoneNumber: "",
  identityType: "",
  identityDocument: [],
  postCode: "",
  permitRequired: false,
  visaDocument: [],
  expiryDate: "",
  email: "",
  password: "",
  confirmPassword: "",
}

const AddNewStaff = ({ open, onClose, staffId, identityTypes, roles, addStaff, errors: backendErrors, validationErrors }: Props) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [formData, setFormData] = React.useState<FormData>(defaultFormData);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: Date | string | null) => {
    if (date) {
      if (typeof date === "string") {
        let value = date.replace(/\D/g, "");
        if (value.length > 4) value = value.slice(0, 4) + "-" + value.slice(4);
        if (value.length > 7) value = value.slice(0, 7) + "-" + value.slice(7, 9);
        setFormData({ ...formData, expiryDate: value });
    }

      if (date instanceof Date) {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setFormData({ ...formData, expiryDate: formattedDate });
      };
    };
  };

  const handleReset = () => {
    setFormData(defaultFormData);
  }

  const handleSubmit = () => {
    const { success, tab } = addStaff(formData);

    if (!success) {
      if (tab !== undefined) setSelectedTab(tab);
      return;
    };
  };

  const isReceivedFile = (file: File | ReceivedFile): file is ReceivedFile => {
    return (file as ReceivedFile).file_name !== undefined;
  };

  const removeFile = (file: File | ReceivedFile) => {
    setFormData({
      ...formData,
      identityDocument: formData.identityDocument.filter((f) => {
        const fileName = isReceivedFile(f) ? f.file_name : f.name;
        const targetFileName = isReceivedFile(file) ? file.file_name : file.name;
        return fileName !== targetFileName;
      }),
      visaDocument: formData.visaDocument.filter((f) => {
        const fileName = isReceivedFile(f) ? f.file_name : f.name;
        const targetFileName = isReceivedFile(file) ? file.file_name : file.name;
        return fileName !== targetFileName;
      }),
    });
  }

  React.useEffect(() => {
    return () => {
      handleReset();
    };
  }, []);

  return (
    <Modal
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <div className="add_new_staff__dialog__header">
        <h2>Add New Staff</h2>
        <IconButton onClick={onClose}>
          <SvgIcon>
            <svg
              width="800"
              height="800"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414"
                fill="#0D0D0D"
              />
            </svg>
          </SvgIcon>
        </IconButton>
      </div>

      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(e, newValue) => setSelectedTab(newValue)}
        classes={{
          root: "add_new_staff__tabs",
          indicator: "add_new_staff__tab__indicator",
        }}
      >
        <Tab
          icon={
            <SvgIcon fontSize="small">
              <svg
                width={16}
                height={16}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.332 6.67a3.332 3.332 0 1 1-3.333-3.333 3.33 3.33 0 0 1 3.333 3.332m-1.667 0c0-.917-.75-1.667-1.666-1.667-.917 0-1.667.75-1.667 1.666 0 .917.75 1.667 1.667 1.667.916 0 1.666-.75 1.666-1.667M10 10.835c-2.225 0-6.667 1.117-6.667 3.333v1.667c0 .458.375.833.833.833h11.667a.836.836 0 0 0 .833-.833v-1.667c0-2.216-4.441-3.333-6.666-3.333m-5 3.342v.825h10v-.834c-.167-.591-2.75-1.666-5-1.666s-4.834 1.075-5 1.675"
                  fill="currentColor"
                />
              </svg>
            </SvgIcon>
          }
          label="General Information"
          classes={{
            root: "add_new_staff__tab",
            selected: "add_new_staff__tab__selected",
          }}
        />
        <Tab
          icon={
            <SvgIcon fontSize="small">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 46.676 46.675"
                xmlSpace="preserve"
              >
                <path d="M42.176 6.318H4.5a4.5 4.5 0 0 0-4.5 4.5v25.04c0 2.485 2.016 4.5 4.5 4.5h37.676a4.5 4.5 0 0 0 4.5-4.5v-25.04a4.5 4.5 0 0 0-4.5-4.5M4.486 29.095c0-.053.012-.104.014-.153.072-2.192 1.646-3.981 4.121-4.953A5.883 5.883 0 0 1 9 15.999q.381-.377.824-.681c.947-.646 2.089-1.022 3.318-1.022s2.37.378 3.316 1.022a5.9 5.9 0 0 1 2.587 4.881 5.87 5.87 0 0 1-1.383 3.789c2.375.932 3.93 2.613 4.114 4.688q.027.208.025.42c0 .882-.352 1.677-.917 2.265a3.26 3.26 0 0 1-2.353 1.006H7.754a3.263 3.263 0 0 1-3.256-3.133c0-.048-.012-.091-.012-.139m37.69 1.38a1.994 1.994 0 0 1-1.986 1.864h-12a2 2 0 0 1-1.713-.981 2 2 0 0 1-.287-1.019 2 2 0 0 1 2-2h12c1.06 0 1.916.824 1.986 1.863.003.047.014.09.014.137s-.011.09-.014.136m0-7.001a1.993 1.993 0 0 1-1.986 1.863h-12a2 2 0 0 1 0-4h12c1.06 0 1.916.824 1.986 1.864.003.046.014.089.014.135-.001.048-.011.091-.014.138m0-7.001a1.994 1.994 0 0 1-1.986 1.864h-12a2 2 0 0 1-2-2c0-.374.109-.72.287-1.019a2 2 0 0 1 1.713-.981h12c1.06 0 1.916.824 1.986 1.864.003.046.014.089.014.136s-.011.09-.014.136" />
              </svg>
            </SvgIcon>
          }
          label="Account Information"
          classes={{
            root: "add_new_staff__tab",
            selected: "add_new_staff__tab__selected",
          }}
        />
      </Tabs>

      {backendErrors && (
        <ul className="add_new_staff__backend_errors">
          {backendErrors.map((error, index) => (
            <li
              key={index}
              className="add_new_staff__backend_error"
            >
              <SvgIcon className="add_new_staff__backend_error_icon">
                <svg
                  width="800"
                  height="800"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0z" />
                </svg>
              </SvgIcon>
              <span className="add_new_staff__backend_error_text">{error}</span>
            </li>
          ))}
        </ul>
      )}

      <div className="add_new_staff__dialog__content">
        {selectedTab === 0 && (
          <div className="add_new_staff__form__grid">
            <FormControl>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Staff ID
              </FormLabel>
              <WingoInput
                value={staffId}
                disabled
              />
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Role
              </FormLabel>
              <Select
                labelId="role-label"
                id="role"
                multiple
                placeholder="Select the roles"
                value={formData.roles}
                onChange={(event) => {
                  const selectedRoleIds = Array.isArray(event.target.value)
                    ? event.target.value.map(Number)
                    : [Number(event.target.value)];
                  setFormData({ ...formData, roles: selectedRoleIds });
                }}
                MenuProps={{
                  classes: {
                    paper: "add_new_staff__menu",
                    list: "add_new_staff__list",
                  },
                }}
                input={<WingoInput />}
                error={!!validationErrors["roles"]}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <WingosChip
                        key={value}
                        label={roles.find((role) => role.id === value)?.name}
                      />
                    ))}
                  </Box>
                )}
              >
                {roles.map((role) => (
                  <MenuItem
                    key={role.id}
                    value={role.id}
                    className="add_new_staff__list_item"
                  >
                    <CustomGreenCheckbox checked={formData.roles.includes(role.id)} />
                    <ListItemText
                      classes={{ primary: "add_new_staff__list_item_text" }}
                      primary={role.name}
                    />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["roles"]}
              >
                {validationErrors["roles"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                First Name
              </FormLabel>

              <WingoInput
                name="firstName"
                placeholder="Enter the first name"
                value={formData.firstName}
                onChange={handleOnChange}
                error={!!validationErrors["firstName"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["firstName"]}
              >
                {validationErrors["firstName"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Last Name
              </FormLabel>

              <WingoInput
                name="lastName"
                placeholder="Enter the last name"
                value={formData.lastName}
                onChange={handleOnChange}
                error={!!validationErrors["lastName"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["lastName"]}
              >
                {validationErrors["lastName"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Mobile Number
              </FormLabel>

              <PhoneInputComponent
                inputStyle={{
                  fontWeight: 400,
                  color: "#1D1D1D",
                  fontFamily: "Barmeno",
                  width: "100%",
                  height: "46px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1D1D1D",
                }}
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                }}
                country={"gb"}
                value={formData.phoneNumber}
                onChange={(value, data) => {
                  if ("dialCode" in data) {
                    setFormData({ ...formData, countryCode: data.dialCode });
                  }
                  setFormData({ ...formData, phoneNumber: value });
                }}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["phoneNumber"]}
              >
                {validationErrors["phoneNumber"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Identity Type
              </FormLabel>

              <Select
                labelId="identity-type-label"
                id="identity-type"
                placeholder="Choose an identity type"
                value={formData.identityType}
                onChange={(e) => setFormData({ ...formData, identityType: e.target.value })}
                input={<WingoInput />}
                MenuProps={{
                  classes: {
                    paper: "add_new_staff__menu",
                    list: "add_new_staff__list",
                  },
                }}
                error={!!validationErrors["identityType"]}
              >
                {identityTypes.map((identityType) => (
                  <MenuItem
                    key={identityType.id}
                    value={identityType.identity_type}
                    className="staff_info__list_item"
                  >
                    <ListItemText
                      classes={{ primary: "add_new_staff__list_item_text" }}
                      primary={identityType.identity_type}
                    />
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["identityType"]}
              >
                {validationErrors["identityType"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Attach Identity Type
              </FormLabel>

              {formData.identityDocument.length > 0 ? (
                <WingosPreview
                  files={formData.identityDocument}
                  removeFile={removeFile}
                />
              ) : (
                <WingosDropzone
                  maxFiles={1}
                  onDropAccepted={(acceptedFiles) => {
                    setFormData({
                      ...formData,
                      identityDocument: acceptedFiles,
                    });
                  }}
                />
              )}
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["identityDocument"]}
              >
                {validationErrors["identityDocument"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Enter your Postcode
              </FormLabel>

              <WingoInput
                name="postCode"
                placeholder="Enter the postcode"
                value={formData.postCode}
                onChange={handleOnChange}
                error={!!validationErrors["postCode"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["postCode"]}
              >
                {validationErrors["postCode"]}
              </FormHelperText>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.permitRequired}
                  onChange={(e) => setFormData({ ...formData, permitRequired: e.target.checked })}
                  classes={{
                    root: "add_new_staff__checkbox",
                    checked: "add_new_staff__checkbox__checked",
                  }}
                />
              }
              classes={{
                root: "add_new_staff__span",
                label: "add_new_staff__checkbox__label",
              }}
              label="Do you require permit to work in UK? (If yes please attach your VISA Document)"
            />

            {formData.permitRequired && (
              <div className="add_new_staff__attach_visa">
                <FormControl required={formData.permitRequired}>
                  <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                    Attach VISA
                  </FormLabel>
                  {formData.visaDocument.length > 0 ? (
                    <WingosPreview
                      files={formData.visaDocument}
                      removeFile={removeFile}
                    />
                  ) : (
                    <WingosDropzone
                      maxFiles={1}
                      onDropAccepted={(acceptedFiles) => {
                        setFormData({
                          ...formData,
                          visaDocument: acceptedFiles,
                        });
                      }}
                    />
                  )}
                  <FormHelperText
                    classes={{ root: "add_new_staff__helper_text" }}
                    error={!!validationErrors["visaDocument"]}
                  >
                    {validationErrors["visaDocument"]}
                  </FormHelperText>
                </FormControl>

                <FormControl required={formData.permitRequired}>
                  <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                    Expiry Date
                  </FormLabel>
                  <DateInput
                    onChange={handleDateChange}
                    onChangeRaw={(e) => handleDateChange(e.target.value)}
                    placeholderText="YYYY-MM-DD"
                    value={formData.expiryDate}
                    minDate={new Date()}
                  />
                  <FormHelperText
                    classes={{ root: "add_new_staff__helper_text" }}
                    error={!!validationErrors["expiryDate"]}
                  >
                    {validationErrors["expiryDate"]}
                  </FormHelperText>
                </FormControl>
              </div>
            )}
          </div>
        )}

        {selectedTab === 1 && (
          <div className="add_new_staff__form__grid">
            <FormControl
              required
              className="add_new_staff__span"
            >
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Email Address
              </FormLabel>
              <WingoInput
                name="email"
                type="email"
                placeholder="Enter the e-mail address"
                value={formData.email}
                onChange={handleOnChange}
                error={!!validationErrors["email"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["email"]}
              >
                {validationErrors["email"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Password
              </FormLabel>
              <WingoInput
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter the password"
                value={formData.password}
                onChange={handleOnChange}
                error={!!validationErrors["password"]}
                endAdornment={
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    size="small"
                  >
                    {showPassword ? <ShowPassword /> : <HidePassword />}
                  </IconButton>
                }
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["password"]}
              >
                {validationErrors["password"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Confirm Password
              </FormLabel>
              <WingoInput
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm the password"
                value={formData.confirmPassword}
                onChange={handleOnChange}
                error={!!validationErrors["confirmPassword"]}
                endAdornment={
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    size="small"
                  >
                    {showConfirmPassword ? <ShowPassword /> : <HidePassword />}
                  </IconButton>
                }
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["confirmPassword"]}
              >
                {validationErrors["confirmPassword"]}
              </FormHelperText>
            </FormControl>
          </div>
        )}

        <div className="add_new_staff__submit_group">
          <Button
            variant="outlined"
            classes={{
              root: "add_new_staff__reset__button",
            }}
            onClick={handleReset}
          >
            Reset
          </Button>

          <Button
            variant="contained"
            classes={{
              root: "add_new_staff__submit__button",
            }}
            onClick={handleSubmit}
          >
            Add Staff
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewStaff;
