import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { BaseInput } from "../../Inputs/CustomInputs";

interface Props {
  value: string;
  handleDateChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  options: { label: string; value: string }[];
};

const SelectDate = ({ value, handleDateChange, options }: Props) => {
  return (
    <Select
        datat-testid="date-filter"
        labelId="date-filter"
        id="date-filter"
        value={value}
        MenuProps={{
          classes: {
            list: "select_date__list",
            paper: "select_date__menu",
          },
        }}
        onChange={handleDateChange}
        classes={{
          select: "select_date__input",
        }}
        input={<BaseInput />}
      >
        {options.map((date) => (
          <MenuItem
            key={date.value}
            classes={{
              root: "select_date__item",
              selected: "select_date__item selected",
            }}
            value={date.value}
          >
            {date.label}
          </MenuItem>
        ))}
      </Select>
  );
};

export default SelectDate;
