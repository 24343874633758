import React from "react";
import { CellContext } from "@tanstack/react-table";
import { IconButton, Pagination, SvgIcon } from "@mui/material";
import { staffInformationColumns } from "../../../../../components/src/Dashboard/DataGrid/columns/StaffInformationColumns";
import DataGrid from "../../../../../components/src/Dashboard/DataGrid";
import { StaffAttributes } from "../../../../../blocks/dashboard/src/dashboards/StaffInformation/StaffInformationController.web";
import "./StaffList.css";

interface Props {
  data: any;
  totalCount: number;
  prevPage: number | null;
  nextPage: number | null;
  totalPages: number;
  currentPage: number;
  handleViewDetails: (id: string) => void;
  handleSelectedRow: (info: CellContext<StaffAttributes, any>) => void;
  handleManageNoteOpen: (id: string) => void;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const StaffList = ({
  data,
  totalCount,
  prevPage,
  nextPage,
  totalPages,
  currentPage,
  handlePageChange,
  handleViewDetails,
  handleSelectedRow,
  handleManageNoteOpen
}: Props) => {
  const actionCell = (info: CellContext<StaffAttributes, any>) => {
    return (
      <div className="action_cell__wrapper">
        <IconButton
          onClick={() => handleViewDetails(info.row.original.id.toLocaleString())}>
          <SvgIcon>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.001 15.336q1.563 0 2.657-1.093 1.093-1.094 1.093-2.657t-1.093-2.657-2.657-1.093q-1.562 0-2.656 1.093-1.094 1.095-1.094 2.657t1.094 2.657Q10.44 15.336 12 15.336m0-1.5a2.17 2.17 0 0 1-1.593-.657 2.17 2.17 0 0 1-.657-1.593q0-.937.657-1.594A2.17 2.17 0 0 1 12 9.336q.938 0 1.595.656.655.657.655 1.594a2.17 2.17 0 0 1-.655 1.593 2.17 2.17 0 0 1-1.595.657m0 4q-2.895 0-5.291-1.53-2.397-1.532-3.625-4.137A.7.7 0 0 1 3 11.91a2.4 2.4 0 0 1 0-.646.7.7 0 0 1 .084-.26q1.23-2.606 3.626-4.136Q9.105 5.336 12 5.336t5.292 1.532q2.396 1.53 3.625 4.135.063.104.083.26a2.5 2.5 0 0 1 0 .645.7.7 0 0 1-.083.261q-1.23 2.604-3.625 4.136-2.395 1.53-5.292 1.53m0-1.667q2.354 0 4.324-1.24a8.14 8.14 0 0 0 3.01-3.343 8.15 8.15 0 0 0-3.01-3.344A7.96 7.96 0 0 0 12 7.002q-2.354 0-4.323 1.24a8.15 8.15 0 0 0-3.01 3.344 8.14 8.14 0 0 0 3.01 3.343A7.96 7.96 0 0 0 12 16.169"
                fill="#747474"
              />
            </svg>
          </SvgIcon>
        </IconButton>

        <IconButton data-testid="edit-staff-action" onClick={() => handleSelectedRow(info)}>
          <SvgIcon>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m13.717 9.517.766.766-7.55 7.55h-.766v-.766zm3-5.017a.83.83 0 0 0-.584.242l-1.525 1.525 3.125 3.125 1.525-1.525a.83.83 0 0 0 0-1.175l-1.95-1.95a.82.82 0 0 0-.591-.242m-3 2.658L4.5 16.375V19.5h3.125l9.217-9.217z"
                fill="#747474"
              />
            </svg>
          </SvgIcon>
        </IconButton>

        <IconButton
          onClick={() =>
            handleManageNoteOpen(info.row.original.id.toString())
          }
        >
          <SvgIcon>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="a"
                style={{
                  maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={2}
                y={2}
                width={20}
                height={20}
              >
                <path
                  fill="#747474"
                  d="M2 2h20v20H2z"
                />
              </mask>
              <g mask="url(#a)">
                <path
                  d="M6.167 19.5q-.688 0-1.177-.49a1.6 1.6 0 0 1-.49-1.177V6.167q0-.688.49-1.177.489-.49 1.177-.49h11.666q.688 0 1.177.49.49.489.49 1.177v5.583a7 7 0 0 0-.812-.323 5 5 0 0 0-.855-.198V6.167H6.167v11.666h5.041q.063.46.198.875.135.417.323.792zm0-1.667V6.167v5.062-.062zm1.666-1.666h3.396q.063-.438.198-.855.135-.416.302-.812H7.833zm0-3.334h5.084q.665-.625 1.49-1.041a5.9 5.9 0 0 1 1.76-.563v-.062H7.833zm0-3.333h8.334V7.833H7.833zM17 21.167q-1.729 0-2.948-1.22Q12.834 18.73 12.833 17q0-1.729 1.22-2.948Q15.27 12.834 17 12.833q1.729 0 2.948 1.22Q21.167 15.27 21.167 17t-1.22 2.948Q18.73 21.167 17 21.167m-.417-1.667h.834v-2.083H19.5v-.834h-2.083V14.5h-.834v2.083H14.5v.834h2.083z"
                  fill="#747474"
                />
              </g>
            </svg>
          </SvgIcon>
        </IconButton>
      </div>
    );
  };

  const columns = staffInformationColumns({ actionCell });

  return (
    <div
      data-testid="staff-list"
      className="staff_information__staff_list"
    >
      <DataGrid
        data={data}
        columns={columns}
        emptyRowsText="No Staff Information found. Please add a new staff."
      />
      <div className="staff_information__pagination__wrapper">
        <span className="staff_information__results">
          Showing {data.length} of {totalCount} results
        </span>

        <Pagination
          hidePrevButton={prevPage === null}
          hideNextButton={nextPage === null}
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default StaffList;
