Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.contentType = "application/json";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.patchMethod = "PATCH";
exports.staffInformationAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff",
  method: "GET"
};
exports.lastStaffIdAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff/last_staff",
  method: "GET"
};
exports.availableRolesAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff/roles",
  method: "GET"
};
exports.availableIdentityTypesAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff/identity_types",
  method: "GET"
};
exports.addStaffAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff",
  method: "POST"
};
exports.updateStaffAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff",
  method: "PUT"
}
exports.staffScheduleAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff_schedule",
};
