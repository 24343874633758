import React from "react";
import { SvgIcon } from "@mui/material";
import { SmartphoneRounded } from "@material-ui/icons";
import { ContainedButton, UnStyledButton } from "../../Button";
import LogoutPopup from "../../../../blocks/settings2/src/LogoutPopup.web";
import "./DriverRole.css";
export const configJSON = require("../../../../blocks/landingpage/src/config.js");

const DriverRole = () => {
  const [logOut, setLogOut] = React.useState(false);

  const openStore = (store: "google" | "apple") => {
    const url = store === "google" ? configJSON.googleStoreURL : configJSON.appleStoreURL;
    window.open(url, "_blank");
  };

  if (logOut) {
    return <LogoutPopup open={logOut} onClose={() => setLogOut(false)} />
  }

  return (
    <div className="driver_role__wrapper">
      <div className="driver_role__container">
        <h1 className="driver_role__title">
          Driver Dashboard Not Available on Web
        </h1>
        <p className="driver_role__description">
          Please download our mobile app to access the driver dashboard and start earning.
        </p>

        <SmartphoneRounded
          fontSize="large"
          style={{ width: 96, height: 96 }}
        />

        <p className="driver_role__description">
          Our driver app is designed for optimal performance on mobile devices. Download it now to get started!
        </p>
        <div className="driver_role__button_group">
          <UnStyledButton
            startIcon={
              <SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 511.999 511.999"
                  xmlSpace="preserve"
                >
                  <path
                    style={{
                      fill: "#32bbff",
                    }}
                    d="M382.369 175.623A775952 775952 0 0 1 79.355 6.028C69.372-.565 57.886-1.429 47.962 1.93l254.05 254.05z"
                  />
                  <path
                    style={{
                      fill: "#32bbff",
                    }}
                    d="M47.962 1.93c-1.86.63-3.67 1.39-5.401 2.308C31.602 10.166 23.549 21.573 23.549 36v439.96c0 14.427 8.052 25.834 19.012 31.761a37 37 0 0 0 5.395 2.314L302.012 255.98z"
                  />
                  <path
                    style={{
                      fill: "#32bbff",
                    }}
                    d="M302.012 255.98 47.956 510.035c9.927 3.384 21.413 2.586 31.399-4.103 143.598-80.41 237.986-133.196 298.152-166.746l4.938-2.772z"
                  />
                  <path
                    style={{
                      fill: "#2c9fd9",
                    }}
                    d="M23.549 255.98v219.98c0 14.427 8.052 25.834 19.012 31.761a37 37 0 0 0 5.395 2.314L302.012 255.98z"
                  />
                  <path
                    style={{
                      fill: "#29cc5e",
                    }}
                    d="M79.355 6.028C67.5-1.8 53.52-1.577 42.561 4.239l255.595 255.596 84.212-84.212A786009 786009 0 0 1 79.355 6.028"
                  />
                  <path
                    style={{
                      fill: "#d93f21",
                    }}
                    d="M298.158 252.126 42.561 507.721c10.96 5.815 24.939 6.151 36.794-1.789 143.598-80.41 237.986-133.196 298.152-166.746l4.938-2.772z"
                  />
                  <path
                    style={{
                      fill: "#ffd500",
                    }}
                    d="M488.45 255.98c0-12.19-6.151-24.492-18.342-31.314 0 0-22.799-12.721-92.682-51.809l-83.123 83.123 83.204 83.205c69.116-38.807 92.6-51.892 92.6-51.892 12.192-6.821 18.343-19.123 18.343-31.313"
                  />
                  <path
                    style={{
                      fill: "#fa0",
                    }}
                    d="M470.108 287.294c12.191-6.822 18.342-19.124 18.342-31.314H294.303l83.204 83.205c69.117-38.806 92.601-51.891 92.601-51.891"
                  />
                </svg>
              </SvgIcon>
            }
            onClick={() => openStore("google")}
          >
            <span>Get it on PlayStore</span>
          </UnStyledButton>
          <UnStyledButton
            startIcon={
              <SvgIcon>
                <svg
                  width={800}
                  height={800}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  fill="currentColor"
                >
                  <path d="M14.94 5.19A4.38 4.38 0 0 0 16 2a4.44 4.44 0 0 0-3 1.52 4.17 4.17 0 0 0-1 3.09 3.69 3.69 0 0 0 2.94-1.42m2.52 7.44a4.51 4.51 0 0 1 2.16-3.81 4.66 4.66 0 0 0-3.66-2c-1.56-.16-3 .91-3.83.91s-2-.89-3.3-.87a4.92 4.92 0 0 0-4.14 2.53C2.93 12.45 4.24 17 6 19.47c.8 1.21 1.8 2.58 3.12 2.53s1.75-.82 3.28-.82 2 .82 3.3.79 2.22-1.24 3.06-2.45a11 11 0 0 0 1.38-2.85 4.41 4.41 0 0 1-2.68-4.04" />
                </svg>
              </SvgIcon>
            }
            onClick={() => openStore("apple")}
          >
            <span>Get it on AppStore</span>
          </UnStyledButton>
        </div>

        <hr className="driver_role__separator" />

        <ContainedButton onClick={() => setLogOut(true)}>
          Log out
        </ContainedButton>
      </div>
    </div>
  );
};

export default DriverRole;
