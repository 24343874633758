import React from "react";
// Customizable Area Start
import FavouriteList from "./../../../components/src/FavouriteList";
import "./Favourites.web.css";
// Customizable Area End

import FavouritesController, { Props } from "./FavouritesController";
import Profile from "./../../../components/src/Profile";

export default class Favourites extends FavouritesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <Profile navigateToPages={this.navigateToPage}>
        <FavouriteList getFavourites={this.getFavourites} favourites={this.state.favourites} deleteFavorite={this.deleteFavorite} isVisible={this.state.isVisible}  navigateToPages={this.props.navigation?.navigate} hideModal={this.hideModal} allergenLists={this.state.allergenList} navigateToMenu={this.navigateToMenu} spiceLevel={this.state.spiceLevelList} getSpiceLevel={this.getSpiceLevel} />
      </Profile>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
