import React from "react";
import { success } from "../../SocialLogin/assets";
import Modal from "../../Modal";
import { ContainedButton } from "../../Button";
import "./ManageNote.css";

interface Props {
  open: boolean;
  onClose: () => void;
}

const NoteSuccess = ({ open, onClose }: Props) => {
  return (
    <Modal
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <div className="note_success__wrapper">
        <img
          src={success}
          alt="Not Saved Successfully"
          className="note_success__image"
        />
        <p className="note_success__text">Note has been saved successfully!</p>
        <ContainedButton onClick={onClose}>
          GO BACK
        </ContainedButton>
      </div>
    </Modal>
  );
};

export default NoteSuccess;
