import React from "react";
import { Button, styled } from "@mui/material";

export const UnStyledButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  color: "#1d1d1d",
  fontFamily: [
    "Barmeno",
    "sans-serif"
  ].join(','),
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  '&:focus': {
  },
});

export const OutlinedButton = styled(Button)({
  height: 56,
  width: "100%",
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px !important',
  border: '1px solid #E11B22 !important',
  lineHeight: 1.5,
  backgroundColor: 'transparent',
  borderColor: '#E11B22',
  borderRadius: "8px !important",
  color: "#E11B22 !important",
  fontFamily: [
    "Barmeno",
    "sans-serif"
  ].join(','),
  '&:hover': {
    backgroundColor: 'rgba(225, 27, 34, 0.1)',
    borderColor: '#E11B22',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  '&:disabled': {
    backgroundColor: "#F1F1F1 !important",
    color: "#909090 !important",
    borderColor: "#F1F1F1 !important",
  },
});

export const ContainedButton = styled(Button)({
  height: 56,
  width: "100%",
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#E11B22 !important',
  borderColor: 'transparent',
  borderRadius: "8px !important",
  color: "#FFFFFF !important",
  fontFamily: [
    "Barmeno",
    "sans-serif"
  ].join(','),
  '&:hover': {
    backgroundColor: '#E11B22 !important',
    borderColor: 'transparent',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  '&:disabled': {
    backgroundColor: "#BFBFBF !important",
    color: "#909090 !important",
  },
});
