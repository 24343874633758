import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import {
  CachedRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
  FilterListRounded,
  FullscreenRounded,
} from "@material-ui/icons";
import ChefDashboardController, { Props } from "./ChefDashboardController.web";
import DashboardWrapper from "../../../../../components/src/Dashboard/DashboardWrapper";
import Header from "../../../../../components/src/Dashboard/Header/Header";
import { ContainedButton, OutlinedButton } from "../../../../../components/src/Button";
import { ContainedIconButton, OutlinedIconButton } from "../../../../../components/src/IconButton";
import CustomGreenCheckbox from "../../../../../components/src/CustomGreenCheckBox.web";
import Modal from "../../../../../components/src/Modal";
import { AllergenIcon, CollectionIcon, DeliveryIcon, ExitFullScreenIcon, FullScreenIcon } from "../../../../../components/src/Icons";
import { Order, CartItem } from "../types";
import "./ChefDashboard.web.css";

export default class ChefDashboard extends ChefDashboardController {
  constructor(props: Props) {
    super(props);
  }

  renderNoOrders = () => {
    return (
      <div className="chef__no_orders__main">
        <p className="chef__no_orders__main__text">No Current Orders. Tap Refresh for Updates</p>
      </div>
    );
  };

  renderOrderType = (orderType: string) => {
    switch (orderType) {
      case "delivery":
        return <DeliveryIcon />;
      case "collection":
        return <CollectionIcon />;
      default:
        return null;
    };
  };

  orderCard = (order: Order) => {
    return (
      <div
        key={order.order_number}
        className="chef__order__card"
      >
        <div className="chef__order__card__header">
          <p className="chef__order__card__header__order_number">
            Order Number
            <br />
            {order.order_number}
          </p>

          <p className="chef__order__card__header__order_type">
            {this.renderOrderType(order.order_type)}
          </p>
        </div>

        {order.chef_note && (
          <div className="chef__order__card__chef_note">
            <p>
            <span className="chef__order__card__chef_note__title">Customer:</span> {order.chef_note}
            </p>
          </div>
        )}

        <div className="chef__order__card__body">
          {order.cart_items.data.map((cartItem: CartItem) => {
            return (
              <div
                key={cartItem.id}
                className="chef__order__card__body__item"
              >
                <p className="chef__order__card__body__item__quantity">{cartItem.attributes.quantity}x</p>

                <div className="chef__order__card__body__item__details">
                  <p className="chef__order__card__body__item__name">{cartItem.attributes.product_name}</p>

                  <div className="chef__order__card__body__item__details__sub_category">
                    {cartItem.attributes.sub_category}
                  </div>

                  {cartItem.attributes.allergen_excluded && (
                    <div
                    data-testid="allergen-excluded"
                    className="chef__order__card__body__item__allergen_excluded">
                      <AllergenIcon width={24} height={24} />
                      <p>{cartItem.attributes.allergen_excluded}</p>
                    </div>
                  )}
                </div>

                <div className="chef__order__card__body__item__checkbox">
                  <CustomGreenCheckbox />
                </div>
              </div>
            );
          })}
        </div>

        <div className="chef__order__card__footer">
          <OutlinedButton>All Ready</OutlinedButton>

          <OutlinedButton disabled>Completed</OutlinedButton>
        </div>
      </div>
    );
  };

  renderOrdersInCooking = () => {
    const { ordersInCooking } = this.state;
    return (
      <div
        data-testid="orders-in-cooking"
        className="chef__main"
      >
        <div className="chef__orders">
          {ordersInCooking.map((order: Order) => {
            return this.orderCard(order);
          })}
        </div>
      </div>
    );
  };

  renderOrdersInQueue = () => {
    const { ordersinQueue } = this.state;
    return (
      <div
        data-testid="orders-in-queue"
        className="chef__main"
      >
        <div className="chef__orders">
          {ordersinQueue.map((order: Order) => {
            return this.orderCard(order);
          })}
        </div>
      </div>
    );
  };

  render() {
    const { navigation } = this.props;
    const { selectedTab, ordersInCooking, ordersinQueue, isFullscreen } = this.state;

    const ordersInCookingTab = ordersInCooking.length > 0 ? this.renderOrdersInCooking() : this.renderNoOrders();

    const ordersInQueueTab = ordersinQueue.length > 0 ? this.renderOrdersInQueue() : this.renderNoOrders();

    return (
      <div ref={this.elementRef}>
        <DashboardWrapper navigation={navigation}>
          <Header
            navigation={navigation}
            headerTitle="Kitchen Dashboard"
          />

          <div className="chef__wrapper">
            <div className="chef__header">
              <Tabs
                value={selectedTab}
                onChange={this.handleTabChange}
                classes={{
                  root: "chef__tab__wrapper",
                  indicator: "chef__tab__hidden_indicator",
                }}
              >
                <Tab
                  label="Cooking"
                  classes={{ root: "chef__tab", selected: "chef__tab__selected" }}
                />
                <Tab
                  label="In Queue"
                  classes={{ root: "chef__tab", selected: "chef__tab__selected" }}
                />
              </Tabs>

              <div className="chef__header__right">
                <OutlinedButton
                  data-testid="filter-button"
                  startIcon={<FilterListRounded />}
                  onClick={this.handleOpenFilters}
                >
                  Filters
                </OutlinedButton>

                <OutlinedIconButton data-testid="refetch-order-list-button" onClick={this.getOrderList}>
                  <CachedRounded />
                </OutlinedIconButton>

                <OutlinedIconButton data-testid="fullscreen-button" onClick={isFullscreen ? this.handleExitFullscreen : this.handleEnterFullscreen}>
                  {isFullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
                </OutlinedIconButton>
              </div>
            </div>

            {selectedTab === 0 ? ordersInCookingTab : ordersInQueueTab}

            <div className="chef__pagination">
              <ContainedIconButton
                disabled={this.state.currentPage === 1}
                onClick={this.handlePreviousPage}
              >
                <ChevronLeftRounded />
              </ContainedIconButton>

              <span>
                {this.state.currentPage} of {this.state.totalPages}
              </span>

              <ContainedIconButton
                disabled={this.state.currentPage === this.state.totalPages}
                onClick={this.handleNextPage}
              >
                <ChevronRightRounded />
              </ContainedIconButton>
            </div>
          </div>

          <Modal
            maxWidth="sm"
            open={this.state.openFilters}
            onClose={this.handleCloseFilters}
          >
            <div className="chef__filters">
              <div className="chef__filters__header">
                <span className="chef__filters__title">Filters</span>
                <IconButton data-testid="close-filters-button" onClick={this.handleCloseFilters}>
                  <CloseRounded />
                </IconButton>
              </div>

              <div className="chef__filters__body">
                <FormControl
                  component="fieldset"
                  className="chef__filters__form"
                >
                  <FormLabel
                    component="legend"
                    className="chef__filters__form__title"
                  >
                    Category
                  </FormLabel>
                  <Select
                    labelId="roles"
                    id="roles"
                    MenuProps={{
                      classes: { paper: "chef__select__menu", list: "chef__select__list" },
                    }}
                    value={this.state.filters.category ?? ""}
                    onChange={this.handleCategoryChange}
                    classes={{
                      root: "chef__select",
                      select: "chef__select__input",
                    }}
                  >
                    {this.categoryOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        classes={{ root: "chef__select__item", selected: "chef__select__item selected" }}
                      >
                        <p className="chef__role_selection__label">{option.label}</p>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className="chef__filters__form"
                >
                  <FormLabel
                    component="legend"
                    className="chef__filters__form__title"
                  >
                    Order Type
                  </FormLabel>
                  <FormGroup className="chef__filters__form__options">
                    <FormControlLabel
                      data-testid="delivery-checkbox"
                      control={
                        <CustomGreenCheckbox
                          checked={this.state.filters.orderType === "delivery"}
                          onChange={(_event, checked) => this.handleOrderTypeChange(checked, "delivery")}
                          name="orderType"
                        />
                      }
                      classes={{ label: "chef__filters__form__options__label" }}
                      label="Delivery"
                    />

                    <FormControlLabel
                      data-testid="collection-checkbox"
                      control={
                        <CustomGreenCheckbox
                          checked={this.state.filters.orderType === "collection"}
                          onChange={(_event, checked) => this.handleOrderTypeChange(checked, "collection")}
                          name="orderType"
                        />
                      }
                      classes={{ label: "chef__filters__form__options__label" }}
                      label="Collection"
                    />
                  </FormGroup>
                </FormControl>
              </div>

              <div className="chef__filters__footer">
                <OutlinedButton onClick={this.handleCloseFilters}>Cancel</OutlinedButton>
                <ContainedButton onClick={this.applyFilter}>Apply Filter</ContainedButton>
              </div>
            </div>
          </Modal>
        </DashboardWrapper>
      </div>
    );
  }
}
