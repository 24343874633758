import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { collection, collectionEnabled, delivery, deliveryEnabled, inStore, inStoreEnabled } from "../../assets";
// Customizable Area Start
export interface CustomerInformation{
  customerName: string;
  customerPhoneNumber: string;
  customerAddress: string;
}
export interface PreviousOrderInfo{
  date: string;
  status: string;
  amount: string;
}
// Customizable Area End

export const webConfigJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  customerInformation:CustomerInformation;
  orderTypeSelection:string;
  callStatus:string;
  previousOrderList:PreviousOrderInfo[];
  // Customizable Area End
}
interface SS {}

export default class PosOrderCreationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loading: false,
      customerInformation:{
      customerName: "",
      customerPhoneNumber: "",
      customerAddress: "" },
      orderTypeSelection:"",
      callStatus:"Down",
      previousOrderList:[{amount:"23.5",date:"22/04/2024",status:"Ready"},{amount:"23.5",date:"22/04/2024",status:"Ready"}]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

    }
    // Customizable Area End
  }
  // Customizable Area Start
  orderTypeSelectionArray=()=>{
    const {orderTypeSelection} = this.state
    let imageArray = [{imagePath:delivery, title:"Delivery", class:"unSelectedOrderType"},{imagePath:collection, title:"Collection", class:"unSelectedOrderType"},{imagePath:inStore, title:"In Store", class:"unSelectedOrderType"}]
    if(orderTypeSelection === "Delivery"){
        imageArray[0].imagePath = deliveryEnabled
        imageArray[0].class = "selectedOrderType"
    } else if( orderTypeSelection === "Collection"){
      imageArray[1].imagePath = collectionEnabled
      imageArray[1].class = "selectedOrderType"
    } else if(orderTypeSelection === "In Store"){
      imageArray[2].imagePath = inStoreEnabled
      imageArray[2].class = "selectedOrderType"
    }
    return imageArray
  }

  provideClassNameWithStatusType=(status:string):string=>{
    if(status == "Approved"){
      return "approved_tag"
    } else if(status == "Pending"){
      return "pending_tag"
    } else  if(status == "Declined"){
      return "declined_tag"
    } else  if(status == "Ready"){
      return "ready_tag"
    } else  if(status == "Picked Up"){
      return "picked_up_tag"
    } else{
      return "delivered_tag"
    } 
  }

  onChangeOrderType=(event:any)=>{
    this.setState({orderTypeSelection: event.currentTarget.accessKey})
  }

  // Customizable Area End
}
