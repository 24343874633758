import React from "react";
import { DateCellWrapperProps } from "react-big-calendar";
import "./DataCellWrapper.css";

const DateCellWrapper = (props: DateCellWrapperProps) => {
  return (
    <div className="calendar__cell__wrapper">
      {props.children}
    </div>
  );
};

export default DateCellWrapper;
