import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {actionCableConfigCloseConnection, calculateRoute, DirectionResultResponse, DirectionsRenderer, MapsMap, trackingWebSocket, webSocketFunction} from "../../../components/src/MapsDistanceRouting"
import { createRef } from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  order_id:number
  token: string
  origin:{lat:number|string|null,long:number|string|null};
  destination:{lat:number|string|null,long:number|string|null}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  directionsRenderer:DirectionsRenderer|null
  map: MapsMap | null;
  directionsResponse: DirectionResultResponse | null;
  origin:{lat:number,long:number};
  destination:{lat:number,long:number}
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];
    this.mapRef=createRef();
    

    this.state = {
      directionsRenderer: null,
      map: null,
      directionsResponse: null,
      origin: this.sanitizeOrigin(this.props.origin),
      destination:this.sanitizeOrigin(this.props.destination)
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  sanitizeOrigin = (origin: { lat: number | string | null; long: number | string | null }) => {
    return {
      lat: Number(origin.lat)||51.509865, 
      long: Number(origin.long)|| -0.118092
    };
  };
  
  mapRef: React.RefObject<any>;
  cableSubscription: any | null = null;
  setDirectionRederer=(value:DirectionResultResponse)=>{
    this.setState({directionsResponse:value})
  }

  componentDidMount=async()=> {
    this.subscribeToStatusUpdates(this.props.token)
    calculateRoute(this.setDirectionRederer,this.state.origin,this.state.destination)
    
  }

  subscribeToStatusUpdates=(token:string)=> {
    this.cableSubscription = webSocketFunction(token)
    trackingWebSocket(this.cableSubscription, this.props.order_id ,this.setOriginLat )
  }

  componentDidUpdate=async(prevProps: Readonly<Props>, prevState: Readonly<S>)=>{
    if(this.state.origin !== prevState.origin){
      this.getDirections()
    }
    if(prevProps.destination !== this.props.destination){
      this.setState({destination:this.sanitizeOrigin(this.props.destination)})
    }if(prevProps.origin !== this.props.origin){
      this.setState({origin:this.sanitizeOrigin(this.props.origin)})
    }
  }

  componentWillUnmount=async()=>{
    if(this.cableSubscription ){
      actionCableConfigCloseConnection(this.cableSubscription)
    }
  }

  getDirections=()=>{
    calculateRoute(this.setDirectionRederer,this.state.origin,this.state.destination)
  }

  setOriginLat=(origin:{lat:number,long:number})=>{
    this.setState({origin})
  }
  // Customizable Area End
}
