Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.getMethod = "GET";
exports.postMethod = "POST";

exports.chefDashboardAPI = {
  endPoint: "bx_block_dashboard/chef_dashboard"
};
