export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const redLocator = require("../assets/d9bc6016a0b426f5262596f5db2e2ae83c74173a.png");
export const blueLocator = require("../assets/f4dceb8ae7a8c18b430612a985fcb20e0ed5e41f.png");
export const searchIcon = require("../assets/da5b3e5d4cb6f8407eb2a159360584aed21237d4.png");
export const closeRound = require("../assets/82fd0a8a12105a80d1fcfd6aba485d619b9b3f67.png");
export const IOSMaps = require("../assets/b50299dc3f7feb7136412e0436f1ee551896e063.png");
export const googleMaps = require("../assets/ff4634359a992ebebdef54e1f5dd51d50fa6670f.png");
export const searchCross = require("../assets/720245bfd0ebca5fe2ad2911b103620f0565fda4.png");
export const checkboxUnselect = require("../assets/checkbox_unselect.png");
export const checkboxselect = require("../assets/checkbox_select.png");
export const restaurant_banner = require("../assets/restaurant_banner.png");
export const restaurant_popup = require("../assets/restaurant_popup.png");
export const logo = require("../assets/logo.svg");
export const noHistory = require("../assets/no_history.png")
export const locationChange = require("../assets/location_change.png")
export const distanceImage = require("../assets/distance.svg")
export const nearestImage = require("../assets/nearest.svg")

