import { toast } from "react-toastify";
import { debounce } from "lodash";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { closeConnection, connection } from "../../../../../components/src/Dashboard/WebSockets";
import { storeAdminConfig } from "../../../../../components/src/Dashboard/WebSockets/StoreAdmin";
import { Order } from "../../../../../components/src/Dashboard/DataGrid/columns/StoreAdminColumns";
import { AutocompleteInputChangeReason } from "@mui/material";

export const configJSON = require("./config");

export type OrderStatistics = {
    current_date: string,
    last_7_days_orders: number,
    week_before_last_7_days_orders: number,
    total_change: number,
    percentage_change: number,
    daily_completed_orders: { date: string; order_count: number; day: string }[];
}

export interface RevenueRecord {
    payment_method: string;
    total_amount: string | number;
    success_count: number;
    success_amount: string | number;
    cancel_count: number;
    cancel_amount: number;
  }

export interface OrderRevenue {
    current_date: string;
    last_7_days_revenue: RevenueRecord[];
    total_last_7_days_revenue: string | number;
    total_previous_week_revenue: number;
    percentage_change: number;
  }

export type Data = {
    active_orders: Order[];
    new_orders_count: number;
    total_orders_count: number;
    completed_orders_count: number;
    percentage_change_in_total_orders: number | string;
    percentage_change_in_completed_orders: number | string;
}

export interface Props {
    navigation: any;
}

export interface S {
    token: string;
    role: string;
    filter: {
        date: "day" | "week" | "month" | "year" | "all_time"
    };
    search: string;
    data: Data;
    orderType: "collection" | "delivery";
    orderStatistics: OrderStatistics;
    orderRevenue: OrderRevenue;
    actionMenuAnchorEl: HTMLElement | null;
    printDialog: boolean;
    receiptText: string;
    printerName: string;
    cupsServerIP: string;
    printReceiptResponse: string;
    orderActionResponse: string;
    searchData: any[];
    searchAnchorEl: HTMLElement | null;
    selectedRowId: string | null;
}

export interface SS {}

export default class StoreAdminController extends BlockComponent<Props, S, SS> {
  orderStatisticsCallId: string = "";
  orderRevenueCallId: string = "";
  printReceiptApiCallId: string = "";
  orderActionCallId: string = "";
  searchCallId: string = "";
  subscription: any | null = null;

  dateFilter = [
    {
      value: "day",
      label: new Date().toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
    },
    { value: "week", label: "Last 7 days" },
    { value: "month", label: "Last 30 days" },
    { value: "year", label: "Last 365 days" },
    { value: "all_time", label: "All time" },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleSearchInputChange = debounce(this.handleSearchInputChange.bind(this), 300);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      searchAnchorEl: null,
      token: "",
      filter: { date: "day" },
      role: "",
      search: "",
      data: {
        active_orders: [],
        new_orders_count: 0,
        total_orders_count: 0,
        completed_orders_count: 0,
        percentage_change_in_total_orders: "N/A",
        percentage_change_in_completed_orders: "N/A",
      },
      orderType: "delivery",
      orderStatistics: {
        current_date: "",
        last_7_days_orders: 0,
        week_before_last_7_days_orders: 0,
        total_change: 0,
        percentage_change: 0,
        daily_completed_orders: [],
      },
      orderRevenue: {
        current_date: "",
        last_7_days_revenue: [],
        total_last_7_days_revenue: 0,
        total_previous_week_revenue: 0,
        percentage_change: 0,
      },
      actionMenuAnchorEl: null,
      printDialog: false,
      receiptText: "",
      printerName: "",
      cupsServerIP: "",
      printReceiptResponse: "",
      orderActionResponse: "",
      searchData: [],
      selectedRowId: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.getToken();
    this.setState({ role: sessionStorage.getItem("role") || "" });
    this.getOrderStatistics();
    this.getOrderRevenue();
    this.subscribe();
  }

  async componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.orderType !== this.state.orderType) {
      this.getOrderRevenue();
    }
  }

  subscribe = () => {
    this.subscription = connection(this.state.token);

    storeAdminConfig({
      subscription: this.subscription,
      setDashboardData: (data: any) => this.setState({ data: data }),
      filter: this.state.filter,
    });
  };

  unsubscribe = () => {
    if (this.subscription) closeConnection(this.subscription);
  };

  getToken = async () => {
    const token = localStorage.getItem("authToken") || "";
    this.setState({ token });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const messageHandlers = {
      [this.orderStatisticsCallId]: () => {
        const data = apiResponse;
        if (data) {
          data.daily_completed_orders = data.daily_completed_orders.map((order: any) => {
            const date = new Date(order.date);
            const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            order.day = days[date.getDay()];
            return order;
          });
          this.setState({ orderStatistics: data });
        }
      },
      [this.orderRevenueCallId]: () => {
        const data = apiResponse;
        console.log("running order revenue call back", data);
        if (data) {
          this.setState({ orderRevenue: data });
        }
      },
      [this.orderActionCallId]: () => {
        const data = apiResponse;
        if (data) {
          this.setState({ orderActionResponse: data.message });
          toast.success(data.message);
        }
      },
      [this.printReceiptApiCallId]: () => {
        const data = apiResponse;
        if (data.status) {
          this.setState({
            printReceiptResponse: data.message,
          });

          setTimeout(() => {
            this.setState({
              printReceiptResponse: "",
            });
          }, 5000);
        }
      },
      [this.searchCallId]: () => {
        const data = apiResponse;

        if (data.message) {
          return;
        }

        const orders = data.orders.map((order: any) => ({
          type: "Order",
          id: order.id,
          label: `${order.order_number}`,
        }));
        const customers = data.customers.map((customer: any) => ({
          type: "Customer",
          id: customer.id,
          label: `${customer.full_name}`,
        }));
        const staff = data.staff.map((staff: any) => ({
          type: "Staff",
          id: staff.id,
          label: `${staff.full_name}`,
        }));
        const result = [...staff, ...orders, ...customers];

        this.setState({ searchData: result });
      },
    };

    const handler = messageHandlers[callId];
    if (handler) handler();
    if (errorMessage) runEngine.debugLog("API Error", errorMessage);
  }

  handlePrint = () => {
    const printData = {
      receipt: this.state.receiptText,
      printer_name: this.state.printerName,
      cups_server: this.state.cupsServerIP,
    };

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.printReceiptApiCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.printReceiptAPI.endPoint);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.printReceiptAPI.contentType,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(printData));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.printReceiptAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  orderAction = (orderId: string, action: "accept" | "cancel" | "skip") => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.orderActionCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderActionAPI.endPoint + "/" + orderId + "/" + configJSON.orderAction[action]
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    if (action === "skip") {
      getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    } else {
      getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    }
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getOrderStatistics = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.orderStatisticsCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.orderStatistics.endPoint);

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.orderStatistics.method);

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  searchQuery = async (search: string) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.searchCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.search.endPoint + "?search_data=" + search + "&token=" + this.state.token
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.search.method);

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getOrderRevenue = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.orderRevenueCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderRevenue.endPoint + "?order_type=" + this.state.orderType
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.orderRevenue.method);

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleSearchInputChange = (_event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    this.setState({ search: value });

    if (value) {
      this.searchQuery(value);
    }
  };
}
