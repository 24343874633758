import React from "react";
// Customizable Area Start
import PosOrderCreationController, {
  Props,
  webConfigJSON
} from "./PosOrderCreationController";
import "./PosOrderCreation.css"
import { IconButton, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import SearchRounded from '@material-ui/icons/SearchRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded"
import Rolesandpermissions2 from "../../../../rolesandpermissions2/src/Rolesandpermissions2.web";
import Sidebar from "../../../../../components/src/Dashboard/Sidebar/Sidebar";
import Header from "../../../../../components/src/Dashboard/Header/Header";
import { buttonMap, notifyButton, delivery, collection, collectionEnabled, inStoreEnabled, notifyButtonDisable } from "../../assets";
// Customizable Area End
export default class PosOrderCreation extends PosOrderCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  customerInformation=()=>{
    const {customerName,customerPhoneNumber,customerAddress} = this.state.customerInformation
    return(
      <div className="customerInformationContainer">
        <div className="CustomerInformationHeading"><div>Customer Information</div> <IconButton className="buttonMapIcon"><img src={buttonMap} alt="buttonMap" /></IconButton> </div>
        <div className="flexColumn24">
        <div className="flexRow24" style={{gap:"16px", alignItems:"last baseline"}}> 
          <div className="flexColumn24" style={{gap:"4px", width:"100%"}}>
            <div style={{color:"#747474"}}>Search By Mobile Number</div>
            <div className="search__wrapper" style={{padding:"0px 0px 0px 12px", border:"1px solid #1d1d1d", fontFamily:"Barmeno"}} >
                        <SearchRounded />
                        <InputBase style={{border:"0px"}}
                        className={"customerSearch"} name="search_by_number" placeholder='Enter Customer’s Mobile Number'/>
                    </div>
          </div>
          <button className="searchButtonCustomer">Search</button>
        </div>
        <div className="customerDetailsCard" > 
          <div className="felxRow24" style={{display:"flex",gap:"16px",width:"fit-content", alignSelf:"end"}}>
            <IconButton className="buttonMapIcon" style={{backgroundColor:"#F1F1F1", color:"#A2A2A2"}}> <EditRoundedIcon/> </IconButton>
            <IconButton className="buttonMapIcon"  style={{backgroundColor:"#F1F1F1", color:"#A2A2A2"}}> <img src={notifyButtonDisable} alt="buttonMap"/> </IconButton>
          </div>
          <div className="personalInformationContainer">
            <div className="personalDetailKey">Name</div><div className="personalDetailValue">{customerName}</div>
            </div>
          <div className="personalInformationContainer">
            <div className="personalDetailKey">Mobile Number</div><div className="personalDetailValue">{customerPhoneNumber}</div>
            </div>
          <div className="personalInformationContainer">
            <div className="personalDetailKey">Address</div><div className="personalDetailValue">{customerAddress}</div>
            </div>
        </div>
        </div>
      </div>
    )
  }

  customerOrderTable=()=>{
    return(
      <TableContainer >
            <Table style={{ minWidth: "428px", maxWidth:"528px", borderSpacing: '0px 0px', borderCollapse: "separate", width: "100%" }} size="medium">
      <TableHead>
      <TableRow>
        {["Date","Status","Amount",""].map((headCell, index) => (
          <TableCell
          className="tableHeaderCells"
            key={index}
            align={"center"}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
      <TableBody>
        {this.state.previousOrderList.map((row) => (
          <TableRow
            hover
            tabIndex={-1}
            key={row.date}
            style={{ cursor: "auto", height:"50px" }}
            className="tableBodyCustomer"
          >
            <TableCell
              align="center"
              className="table_cell_posOrderHistory"
            >
              {row.date}
            </TableCell>
            <TableCell className="table_cell_posOrderHistory" align="center"><div className={this.provideClassNameWithStatusType(row.status)}>{row.status}</div></TableCell>
            <TableCell className="table_cell_posOrderHistory" align="center">£{row.amount}</TableCell>
            <TableCell className="table_cell_posOrderHistory"><IconButton id='openPopup' ><KeyboardArrowRightRoundedIcon /> </IconButton></TableCell>
          </TableRow>
        )
        )}
      </TableBody>
      </Table>
          </TableContainer>
    )
  }

  customerPreviousOrder=()=>{
    return(
      <div className="previousOrderContainer">
        <div className="flexColumn24">
          <div className="headingStyle" style={{color:"#1D1D1D"}}>
          Previous Orders
          </div>
          <div className="headingStyle" style={{color:"#747474"}}>
          No previous orders
          </div>
        </div>
        <div  className="flexRow24"> 
          <button className="repeatOrderButton">Repeat Order</button>
          <button className="newOrderButton">New Order</button>
        </div>
      </div>
    )
  }

  customerRelatedDetails=()=>{
    return(
      <div className="flexRow24">
        {this.customerInformation()}
      {this.customerPreviousOrder()}
      </div>
    )
  }
  orderTypeSelection=()=>{
    return(
      <div className="flexColumn24">
        <div className="headingStyle" style={{color:"#1D1D1D"}} >Order Type Selection</div>
        <div className="flexRow24" style={{gap:"40px", alignItems:"center", justifyContent:"center"}}>
          {this.orderTypeSelectionArray().map(value=>
            <div accessKey={value.title} key={value.title} className="orderTypeContainer" onClick={this.onChangeOrderType}>
            <div className={value.class}><img src={value.imagePath} alt={value.imagePath} /></div>
            <div className="headingStyle" style={{color: value.class ==="unSelectedOrderType"? "#1D1D1D":"#2C6F37"}}>{value.title}</div>
          </div>
          )}
        </div>
      </div>
    )
  }

  callerIdInformation=()=>{
    return(
      <div className="flexColumn24">
        <div className="headingStyle">Caller ID Information</div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          {["LINE 1","LINE 2","LINE 3","LINE 4","LINE 5"].map((value)=>
          <div className="phoneLineContainer">{value}</div>
          )}
        </div>
        <div className="flexRow24" style={{gap:"40px", alignItems:"center"}}>
          <div style={{color:"#747474",fontSize:"18px",fontWeight:500}}>Call Status: <span style={{color:"#1D1D1D"}}>{this.state.callStatus}</span></div>
          <div style={{color:"#747474",fontSize:"18px",fontWeight:500}}>Call Started:  <span style={{color:"#1D1D1D"}}>0</span></div>
          <button disabled={this.state.callStatus ==="Down"} className={this.state.callStatus ==="Down" ? "repeatOrderDisableButton":"repeatOrderButton"}>CLEAR PHONE NUMBER</button>
        </div>
      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div data-testid="dashboard-wrapper" className="dashboard__wrapper">
        <aside data-testid="dashboard-sidebar" className="dashboard__sidebar">
          <Sidebar navigation={this.props.navigation} />
        </aside>

        <main data-testid="dashboard-main" className="dashboard__main">
          <Header navigation={this.props.navigation} headerTitle={"New Order"} />
          <div className="newOrderCreationContainer">
        {this.customerRelatedDetails()}
        {this.orderTypeSelection()}
        {this.callerIdInformation()}
      </div>
        </main>

        <Rolesandpermissions2 id="Popup" navigation={this.props.navigation} />
      </div>
     
    );
    // Customizable Area End
  }
}
