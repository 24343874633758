import {useJsApiLoader} from '@react-google-maps/api';

export const configJSON = require("../../framework/src/config")
export type DirectionsRenderer = google.maps.DirectionsRenderer
export type MapsMap = google.maps.Map;
export type DirectionResultResponse =  google.maps.DirectionsResult

export const scaledSize =  typeof window !== "undefined" && window.google
? new window.google.maps.Size(60, 60): undefined
export const origin = typeof window !== "undefined" && window.google
?new window.google.maps.Point(0, 0) :undefined
export const anchor= typeof window !== "undefined" && window.google
? new window.google.maps.Point(30, 30): undefined


export const calculateRoute = async(setDirectionRederer:(value:google.maps.DirectionsResult)=>void,origins:{lat:number,long:number}, destinations:{lat:number,long:number}) => {
    const origin = new google.maps.LatLng(origins.lat,origins.long)
    const destination = new google.maps.LatLng(destinations.lat,destinations.long)
  console.log("origin", origin,destination)
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin,
      destination,
      travelMode: google.maps.TravelMode.DRIVING,
    });
    console.log("results",JSON.stringify(results))

    setDirectionRederer(results)
  };

  export const webSocketFunction = (token:string) =>{
    const baseURL =  configJSON.baseURL.replace('https://', '');
    const socket = new WebSocket(`wss://${baseURL}/cable?token=${token}`);
    return socket
}

export const trackingWebSocket = (cableSubscription:any | null, order_id:number,setOriginLat:(value:{lat:number,long:number})=>void)=>{
  if(cableSubscription){
    const socket = cableSubscription
    socket.onopen = () => {
        console.log('WebSocket connection established');
        const message = {
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "BxBlockMaps3::OrderDriverLocationChannel",
            order_id
          })
        };
      
        socket.send(JSON.stringify(message));
      };
      socket.onmessage = (event:any) => {
        const response = event.data;
        const message = JSON.parse(response);

        if (message.type === 'confirm_subscription') {
          console.log('Successfully subscribed to channel:', message.identifier);
        } else if (message.type === 'ping') {
          console.log("ping")
          return;
        }
        console.log('WebSocket message received:', message);
        if (message.message) {
          const driverLocation = message.message.driver_location
          console.log("message payment intent",message)
          if (driverLocation) {
            console.log(`Driver's location: Latitude: ${driverLocation.latitude}, Longitude: ${driverLocation.longitude}`);
            setOriginLat({lat:driverLocation.latitude,long:driverLocation.longitude})
          }
        }else {
          console.warn('Unexpected data received:', message);
        }
      };
      
      socket.onerror = (error:any) => {
        console.error('WebSocket error:', error);
        
      };
    }
}

export const actionCableConfigCloseConnection = (cableSubscription:any | null)=>{
  cableSubscription.onclose = () => {
      console.log('WebSocket connection closed');
    };
}