import { OrderHistoryOrders } from "../../blocks/ordermanagement/src/OrderHistoryController";

export const configJSON = require("../../framework/src/config")

export const webSocketFunction = (token:string) =>{
    const baseURL =  configJSON.baseURL.replace('https://', '');
    const socket = new WebSocket(`wss://${baseURL}/cable?token=${token}`);
    return socket
}


export const actionCableConfig = (cableSubscription:any | null, setActiveOrderState: (orderHistory: OrderHistoryOrders[],oldOrderHistory: OrderHistoryOrders[]) => void)=>{
    if(cableSubscription){
    const socket = cableSubscription
    socket.onopen = () => {
        console.log('WebSocket connection established');
        const message = {
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "BxBlockOrderManagement::OrderTrackingChannel",
          })
        };
      
        socket.send(JSON.stringify(message));
      };
      socket.onmessage = (event:any) => {
        const response = event.data;
        const message = JSON.parse(response);
      
        if (message.type === 'ping') {
          return;
        }
        
        console.log('WebSocket message received:', message);
        // Check if the message contains order status updates
        if (message.message && message.message.data && message.message.data.active_orders) {
          setActiveOrderState(message.message.data.active_orders, message.message.data.past_orders)
        }
      };
      
      socket.onerror = (error:any) => {
        console.error('WebSocket error:', error);
        
      };
    }
}

export const paymentWebsocketFunc = (cableSubscription:any | null, paymentIntent:string, openPaymentWaiting:(value:boolean)=>void, openPaymentPopup:(img:string,button:string,heading:string,content:string,attempts?:number)=>void,setPaymentStatus: (status: boolean) => void)=>{
  if(cableSubscription){
    const socket = cableSubscription
    socket.onopen = () => {
        console.log('WebSocket connection established');
        const message = {
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "BxBlockStripeIntegration::PaymentStatusChannel",
            payment_intent_id: paymentIntent
          })
        };
      
        socket.send(JSON.stringify(message));
      };
      socket.onmessage = (event:any) => {
        const response = event.data;
        const message = JSON.parse(response);
      
        if (message.type === 'ping') {
          console.log("ping")
          return;
        }
        
        console.log('WebSocket message received:', message);
        if (message.message) {
          console.log("message payment intent",message)
          if (message.message.data && message.message.data.attributes && message.message.data.attributes.status && message.message.data.attributes.status ==="succeeded" ) {
            openPaymentWaiting(false)
            setPaymentStatus(true)
            openPaymentPopup("success", "CLOSE", 'Payment Successful!', "Your payment for the order has been made successfully!")
            console.log(`Payment Status: ${message.message.data.attributes}`);
          } else if(message.message.error && message.message.error === "Payment failed"){
            openPaymentWaiting(false)
            openPaymentPopup( "failure", "TRY AGAIN", 'Payment Failed!', "Payment unsuccessful. Your order has been canceled.",message.message.payment_attempts)
            console.log("payment status", message.message)
          } else if(message.message.payment.status && message.message.payment.status === "succeeded"){
            openPaymentWaiting(false)
            setPaymentStatus(true)
            openPaymentPopup("success", "CLOSE", 'Payment Successful!', "Your payment for the order has been made successfully!")
          } else if(message.message.payment.status && message.message.payment.status === "succeeded"){
            openPaymentWaiting(false)
            openPaymentPopup( "failure", "TRY AGAIN", 'Payment Failed!', "Payment unsuccessful. Your order has been canceled.",message.message.payment_attempts)
            console.log("payment status", message.message)
          }
        }else {
          console.warn('Unexpected data received:', message);
        }
      };
      
      socket.onerror = (error:any) => {
        console.error('WebSocket error:', error);
        
      };
    }
}

interface HeaderCountConfigProps {
  cableSubscription: any | null;
  setCartCount: (cartCount: number) => void;
  setNotificationCount: (notificationCount: number) => void;
  forceUpdate: () => void;
}

export const headerCountConfig = ({ cableSubscription, setCartCount, setNotificationCount, forceUpdate }: HeaderCountConfigProps) => {
  if (cableSubscription) {
    const socket = cableSubscription

    socket.onopen = () => {
      console.log('WebSocket connection established');
      const cartCountMessage = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "BxBlockShoppingCart::CartItemCountChannel",
        })
      };

      const notificationCountMessage = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "BxBlockNotifications::NotificationCountChannel",
        })
      };

      socket.send(JSON.stringify(cartCountMessage));
      socket.send(JSON.stringify(notificationCountMessage));
    };

    socket.onmessage = (event: any) => {
      const response = event.data;
      const message = JSON.parse(response);

      if (message.type === 'ping') {
        return;
      }

      console.log('WebSocket message received:', message);
      // Check if the message contains order status updates
      if (message.message && message.message.data && message.message.data.cart_item_count !== undefined) {
        setCartCount(message.message.data.cart_item_count)
        localStorage.setItem('cartCount', message.message.data.cart_item_count)
        forceUpdate()
      }

      if (message.message && message.message.data && message.message.data.notification_count !== undefined) {
        setNotificationCount(message.message.data.notification_count)
        localStorage.setItem('notificationCount', message.message.data.notification_count)
        forceUpdate()
      }
    };

    socket.onerror = (error: any) => {
      console.error('WebSocket error:', error);
      setCartCount(0)
      setNotificationCount(0)
      localStorage.setItem('cartCount', '0')
      localStorage.setItem('notificationCount', '0')
    };
  }
}

export const actionCableConfigCloseConnection = (cableSubscription:any | null)=>{
    cableSubscription.onclose = () => {
        console.log('WebSocket connection closed');
      };
}