import React from "react";
import Modal from "../../../Modal";
import { ContainedButton } from "../../../Button";
import { success } from "../../../../../blocks/settings2/src/assets";
import "./CreateStaffSuccess.css";

interface Props {
  open: boolean;
  onClose: () => void;
};

const CreateStaffSuccess = ({ open, onClose }: Props) => {
  return (
    <Modal closeButton maxWidth="xs" open={open} onClose={onClose}>
      <div className="created_staff__wrapper">
        <img src={success} alt="success" />
        <p className="created_staff__title">Staff Account has been added successfully.</p>
        <ContainedButton onClick={onClose}>
          BACK TO STAFF INFORMATION
        </ContainedButton>
      </div>
    </Modal>
  )
};

export default CreateStaffSuccess;
