import React from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneInput.css";

const PhoneInputComponent = (props: PhoneInputProps) => {
  return (
    <PhoneInput
      {...props}
      containerClass="phone-input-container"
      inputClass="phone-input"
      buttonClass="phone-input-button"
      dropdownClass="phone-input-dropdown"
      searchClass="phone-input-search"
    />
  );
};

export default PhoneInputComponent;
