import React from "react";
// Customizable Area Start
import Rolesandpermissions2 from "../../rolesandpermissions2/src/Rolesandpermissions2.web";
import "./Dashboard.css";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Rolesandpermissions2 id="Popup" navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
