import React,{Component}  from "react";
import { Box, Button, FormControl, IconButton, OutlinedInput, Typography} from "@material-ui/core";
//@ts-ignore
import footerLogo from "./image_logo(1).png";
import './AppFooter.css';
import UnsubscribeNewsLetter from "../../blocks/customform/src/UnsubscribeNewsLetter.web";
import UnSubscribeContent from "./UnSubscribeContent/UnSubscribeContent";
import DialogPopup from "./DialogPopup.web";
import SubscriptionSuccess from "../../components/src/SubscriptionSuccess/SubscriptionSuccess";
import AlreadySubscribed from "../../components/src/AlreadySubscribed/AlreadySubscribed";
import { subscribeNewsLetter, socialMediaLinks } from "./apiCall";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { appStoreIcon, playStoreIcon } from "./assets";
export const configJSON = require("../../blocks/landingpage/src/config.js");

interface Props {
    // Customizable Area Start
    navigation: string;
    id: string;
    // Customizable Area End
  }

  interface S {
    email: string,
    open: boolean,
    subscriptionSuccess: boolean,
    unsubscriptionSuccess: boolean,
    emailIncorrect: boolean;
    subscribed: boolean;
    socialIcons: any[]
  }

class AppFooter extends Component<Props, S>{
    emailReg: RegExp;

    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            open: false,
            subscriptionSuccess: false,
            unsubscriptionSuccess: false,
            emailIncorrect: false,
            subscribed: false,
            socialIcons: []
        }
        this.emailReg = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

        this.emailChange = this.emailChange.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.closeSubscribedPopup = this.closeSubscribedPopup.bind(this);
        this.closeUnsubscribeSuccess = this.closeUnsubscribeSuccess.bind(this);
        this.closeSubscriptionSuccess = this.closeSubscriptionSuccess.bind(this);
        this.openUnsubscriptionSuccess = this.openUnsubscriptionSuccess.bind(this);
        this.getSoicalIcons = this.getSoicalIcons.bind(this);
    }

    async componentDidMount() {
        const params = new URLSearchParams(location.search);
        const modal = params.get("modal") || "";
        if(modal === "unsubscribe") {
            this.setState({open: true});
        }
        this.getSoicalIcons();
    }

    emailChange(value: string) {
        this.setState({email: value, emailIncorrect: false})
    }

    openAppleStore = () => {
        const appleUrl = configJSON.appleStoreURL;
        window.open(appleUrl, '_blank');
      };
    
      openGoogleStore = () => {
        const googleUrl = configJSON.googleStoreURL;
        window.open(googleUrl, '_blank');
      };

    getSoicalIcons() {
        socialMediaLinks().then((response) => {
            if(response?.status === 200) {
                this.setState({socialIcons: response?.data.data})
            }
        })
    }

    subscribe() {
        if(!this.emailReg.test(this.state.email)) {
            this.setState({emailIncorrect: true});
        }
        subscribeNewsLetter(this.state.email).then((response) => {
            if(response?.status === 201) {
                this.setState({subscriptionSuccess: true});
            } else if(response?.status === 200) {
                this.setState({subscribed: true});
            }
        })
    }

    socialIcons() {

    }

    closeSubscribedPopup() {
        this.setState({subscribed: false})
    }

    closePopup(open: boolean) {
        this.setState({open});
    }

    closeSubscriptionSuccess() {
            this.setState({subscriptionSuccess: false,})
    }

    closeUnsubscribeSuccess() {
        this.setState({unsubscriptionSuccess: false});
    }

    openUnsubscriptionSuccess() {
        this.setState({unsubscriptionSuccess: true});
    }

    navigateAfterSubscribe() {
        const token = localStorage.getItem('authToken') || "";

        if (token) {
            location.href = location.origin;
            this.closeSubscriptionSuccess();
        } else {
            location.href = location.origin + '/accountregistration';
            this.closeSubscriptionSuccess();
        }
    }
    navigateTopPages=(navigator:string)=>{
            location.href = location.origin + navigator
    }

    navigateToLinks(socialMedia:string){
        window.open(socialMedia, '_blank');
    }


    render(){
        return(
            <>
            {console.log(location,"location")}
            <div>
            <Box className="mainDiv" >
                <Box className="firstDiv" >
                    <Box className="InsideFirstDiv" >
                    <Box className="footerLeftDiv" >
                        <Box className="logoStyle" >
                            <img style={{aspectRatio:"1.90", width:"60px"}} src={footerLogo} />
                        </Box>
                        <Box className="logoPara" style={{fontFamily:'Barmeno'}}>
                            For further information about this website or any feedback, please contact Tops Pizza Head Office on:
                        </Box>
                        <Box className="logoPara" style={{fontFamily:'Barmeno'}}>
                            Tops Pizza Limited

                            Unit 5 & 6, Network 4 Cressex Business Park.

                            Lincoln Road,

                            High Wycombe, HP12 3RF

                            Tel: 020 8226 2060

                            Fax: 020 8226 2070
                        </Box>
                                    <Box className="footer_social_icons">
                                        {this.state.socialIcons.map((icon) => {
                                            return (
                                                <IconButton style={{ width: 48, height: 48, background: "transparent", border: "0px" }} onClick={() => this.navigateToLinks(icon.attributes.link)}>
                                                    <img src={icon.attributes.image.url} style={{ width: "24px", aspectRatio: "1" }} alt={icon.attributes.image.title} />
                                                </IconButton>
                                            )
                                        })}
                                    </Box>
                    </Box>
                    <Box className="contactBlocks" >
                        <Box className="contactDiv" >
                            <Typography className="headings" style={{fontFamily:'Verona'}}>Contact Us</Typography>
                            <Box className="footerList" >
                                <Typography className="listItems" style={{fontFamily:'Barmeno'}}>
                                    <div itemID="goToOurStory" onClick={() => {this.navigateTopPages('/OurStory')}}  className="links">
                                        Our Story
                                    </div>
                                </Typography>
                                
                                <Typography className="listItems" style={{fontFamily:'Barmeno'}}>
                                    <div itemID="goToBecomeFranchise" onClick={() => {this.navigateTopPages('/Franchise')}}  className="links">
                                        Become a Franchise
                                    </div>
                                </Typography>
                                <Typography className="listItems" style={{fontFamily:'Barmeno'}}>
                                    <div itemID="goToHeadOffice" onClick={() => {this.navigateTopPages('/HeadOffice')}} className="links">
                                        Head Office 
                                    </div>
                                </Typography>
                                <Typography className="listItems" style={{fontFamily:'Barmeno'}}>
                                   <div style={{display:"flex", gap:"4px", alignItems:"center", height:"24px"}}>
                                    <div itemID="goToMobileApps"  className="links">
                                        Mobile Apps 
                                    </div>
                                    <IconButton style={{height:"24px",width:"24px",background:"transparent" , border:"0px"}} onClick={this.openGoogleStore}>
                                    <img src={playStoreIcon} style={{width:"24px", aspectRatio:"1"}} alt="play_store" />
                                    </IconButton>
                                    <IconButton style={{height:"24px",width:"24px",background:"transparent" , border:"0px"}} onClick={this.openAppleStore}>
                                    <img src={appStoreIcon} style={{width:"24px", aspectRatio:"1"}} alt="app_store" />
                                    </IconButton>
                                    
                                    </div>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="contactDiv" >
                        <Typography className="headings" style={{fontFamily:'Verona'}}>Policies</Typography>
                            <Box className="footerList" >
                                <Typography className="listItems" style={{fontFamily:'Barmeno'}}>
                                    <div itemID="goToTermsConditions" onClick={() => {this.navigateTopPages('/TermsConditions')}}  className="links" >
                                        Terms & Conditions
                                    </div>
                                    </Typography>
                                    <Typography className="listItems" style={{fontFamily:'Barmeno'}}>
                                    <div itemID="goToAllergen" onClick={() => {this.navigateTopPages('/AllergenInformation')}}  className="links">
                                        Allergen Information
                                    </div></Typography>
                                <Typography className="listItems" style={{fontFamily:'Barmeno'}}>
                                    <div itemID="goToBetterChicken" onClick={() => {this.navigateTopPages('/BetterChicken')}}  className="links">
                                        Better Chicken
                                    </div></Typography>
                                <Typography className="listItems" style={{fontFamily:'Barmeno'}}>
                                    <div itemID="goToPrivacyPolicy" onClick={() => {this.navigateTopPages('/PrivacyPolicy')}} className="links">
                                        Privacy Policy
                                    </div>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    </Box>
                    <Box className="infoDiv">
                            <Box className="infoInternal">
                                <Typography className="infoHeading" style={{fontFamily:'Barmeno'}}>Sign Up for Wingo’s Newsletter</Typography>
                                <Typography className="infoPara" style={{fontFamily:'Barmeno'}}>Get information of our discounts and latest deals in a lightning speed</Typography>                            
                            </Box>
                            <Box>
                            <form noValidate autoComplete="off" className="subscription_form">
                                <FormControl className="emailInput" style={{fontFamily:'Barmeno', position: 'relative'}}>
                                     <OutlinedInput error={this.state.emailIncorrect} onChange={(e) => this.emailChange(e.target.value)} value={this.state.email} className="emailPlaceholder" style={{fontFamily:'Barmeno'}} placeholder="Enter your e-mail address" />
                                </FormControl>
                                {this.state.emailIncorrect && (<span className="subscribe_error">The email address you entered is not in a valid format. Please enter a valid email address</span>)}
                            </form>
                            </Box>
                            <Box><Button className="formBtn" style={{fontFamily:'Barmeno',textTransform:'none'}} variant="contained" onClick={() => this.subscribe()}>Subscribe</Button></Box>
                        </Box>
                </Box>
                {/* <hr className="footerHr"  /> */}
                <Box className="secondDiv" >
                    <Typography className="secondPara" style={{fontFamily:'Barmeno'}}>© 2024 Wingo’s | All Rights Reserved
                    </Typography>
                </Box>
            </Box>
            <DialogPopup open={this.state.subscribed} onClose={this.closeSubscribedPopup} ><AlreadySubscribed navigate={this.navigateAfterSubscribe} close={this.closeSubscribedPopup}></AlreadySubscribed></DialogPopup>
            <DialogPopup open={this.state.subscriptionSuccess} onClose={this.closeSubscriptionSuccess} closeIcon={false}><SubscriptionSuccess navigate={this.navigateAfterSubscribe}></SubscriptionSuccess></DialogPopup>
            <DialogPopup open={this.state.open} onClose={this.closePopup} closeIcon={false}><UnsubscribeNewsLetter close={this.closePopup} openSuccessPopup={this.openUnsubscriptionSuccess}></UnsubscribeNewsLetter></DialogPopup>
            <DialogPopup open={this.state.unsubscriptionSuccess} onClose={this.closePopup} closeIcon={false}><UnSubscribeContent close={this.closeUnsubscribeSuccess}></UnSubscribeContent></DialogPopup>
            </div>
            </>
        )
    }
}

export default AppFooter;