import { CellContext } from "@tanstack/react-table";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { StaffGeneralInfoSchema, PermitSchema, StaffAccountInfoSchema } from "../../../../../components/src/Schemas/AddNewStaff";
import { z } from "zod";
import { Event } from "react-big-calendar";

export const configJSON = require("./config");

export type ReceivedFile = {
  url: string;
  content_type: string;
  file_name: string;
  size?: number;
};

export type Role = {
  id: number;
  name: string;
}

export interface StaffAttributes {
  id: number;
  staff_id: string;
  user_roles: Role[];
  full_name: string;
  first_name: string;
  last_name: string;
  country_code: string;
  phone_number: string;
  email: string;
  full_phone_number: string;
  identity_type: string;
  identity_document: ReceivedFile[];
  permit_require: boolean;
  visa: ReceivedFile[];
  expiry_date: string;
  post_code: string;
};

export type Staff = {
  id: string;
  type: string;
  attributes: StaffAttributes;
};

export interface Props {
    navigation: any;
}

export interface S {
    token: string;
    role: string;
    selectedTab: number;
    data: StaffAttributes[];
    totalCount: number;
    totalPages: number;
    currentPage: number;
    nextPage: number | null;
    prevPage: number | null;
    openAddStaff: boolean;
    openUpdateStaff: boolean;
    staffId: string;
    availableIdentityTypes: { id: number; identity_type: string }[];
    availableRoles: { id: number; name: string }[];
    errors?: string[];
    validationErrors: Record<string, string>;
    roleFilter: number;
    search: string;
    selectedRow?: StaffAttributes;
    openConfirmation: boolean;
    selectedId: string | null;
    openManageNote: boolean;
    openNoteSuccess: boolean;
    staffCreated: boolean;
    events: Event[];
    staff: {
      "Chef": any[],
      "Online Order Manager": any[],
      "In Store Operator": any[],
      "Rider/Driver": any[],
      "In Store Manager": any[],
    };
}

export interface SS {}

export default class StaffInformationController extends BlockComponent<Props, S, SS> {
  staffInformationCallId: string = "";
  addStaffCallId: string = "";
  updateStaffCallId: string = "";
  lastStaffIdCallId: string = "";
  identityOptionsCallId: string = "";
  getAvailableRolesCallId: string = "";
  addNoteCallId: string = "";
  staffByRoleCallId: string = "";

  mockEvents: Event[] = [
    {
      allDay: true,
      title: "Project Kickoff",
      start: new Date(2024, 10, 7, 12, 0),
      end: new Date(2024, 10, 7, 12, 0),
      resource: {
        location: "Online",
        description: "Initial project kickoff meeting.",
      },
    },
    {
      allDay: false,
      title: "Design Review",
      start: new Date(2024, 10, 8, 10, 0),
      end: new Date(2024, 10, 8, 11, 0),
      resource: {
        location: "Conference Room B",
        description: "Review design mockups with the team.",
      },
    },
    {
      allDay: false,
      title: "Client Presentation",
      start: new Date(2024, 10, 9, 14, 0),
      end: new Date(2024, 10, 9, 15, 0),
      resource: {
        location: "Client Office",
        description: "Present the project plan to the client.",
      },
    },
    {
      allDay: true,
      title: "Team Building Activity",
      start: new Date(2024, 10, 10),
      end: new Date(2024, 10, 10),
      resource: {
        location: "Outdoor Park",
        description: "Team building activities and games.",
      },
    },
    {
      allDay: false,
      title: "Code Review",
      start: new Date(2024, 10, 11, 16, 0),
      end: new Date(2024, 10, 11, 17, 0),
      resource: {
        location: "Conference Room A",
        description: "Review code changes with the development team.",
      },
    },
    {
      allDay: false,
      title: "Marketing Strategy Meeting",
      start: new Date(2024, 10, 12, 9, 0),
      end: new Date(2024, 10, 12, 10, 0),
      resource: {
        location: "Meeting Room 3",
        description: "Discuss marketing strategies for the new product launch.",
      },
    },
    {
      allDay: true,
      title: "Product Launch",
      start: new Date(2024, 10, 13),
      end: new Date(2024, 10, 13),
      resource: {
        location: "Main Auditorium",
        description: "Official launch of the new product.",
      },
    },
  ];

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      role: "",
      selectedTab: 0,
      data: [],
      totalCount: 0,
      totalPages: 0,
      currentPage: 1,
      nextPage: null,
      prevPage: null,
      openAddStaff: false,
      openUpdateStaff: false,
      staffId: "",
      availableIdentityTypes: [],
      availableRoles: [],
      roleFilter: 0,
      search: "",
      validationErrors: {},
      openConfirmation: false,
      selectedId: null,
      openManageNote: false,
      openNoteSuccess: false,
      staffCreated: false,
      events: [],
      staff: {
        "Chef": [],
        "Online Order Manager": [],
        "In Store Operator": [],
        "Rider/Driver": [],
        "In Store Manager": [],
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.getToken();

    const role = sessionStorage.getItem("role");
    if (role) this.setState({ role });

    this.getStaffInformation();
    this.getAvailableRoles();
    this.getStaffByRole();
  }

  async getToken() {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  }

  getMessageHandler(callId: string, apiResponse: any) {
    const userDetail = JSON.parse(sessionStorage.getItem("userDetail") || "{}");
    const messageHandlers = {
      [this.staffInformationCallId]: () => this.handleStaffInformation(apiResponse),
      [this.addStaffCallId]: () => this.handleAddStaff(apiResponse),
      [this.updateStaffCallId]: () => this.handleUpdateStaff(apiResponse),
      [this.lastStaffIdCallId]: () => this.handleLastStaffId(apiResponse, userDetail),
      [this.identityOptionsCallId]: () => this.handleAvailableIdentity(apiResponse),
      [this.getAvailableRolesCallId]: () => this.handleAvailableRoles(apiResponse),
      [this.addNoteCallId]: () => this.handleAddNote(apiResponse),
      [this.staffByRoleCallId]: () => this.handleStaffByRole(apiResponse),
    };

    return messageHandlers[callId];
  }

  handleStaffByRole = (apiResponse: any) => {
    if (apiResponse.staff_by_role) {
      let result: any = {};

      Object.keys(apiResponse.staff_by_role).forEach((role) => {
        if (!result[role]) {
          result[role] = [];
        }
        apiResponse.staff_by_role[role].forEach((staff: any) => {
          result[role].push({
            id: staff.attributes.id,
            staff_id: staff.attributes.staff_id,
            label: staff.attributes.full_name,
          });
        });
      });

      this.setState({
        staff: result,
      });
    }
  };

  handleAddNote = (apiResponse: any) => {
    if (apiResponse.message) this.setState({ openNoteSuccess: true });

    this.handleManageNoteClose();
  };

  handleStaffInformation(data: any) {
    if (data.staff_members) {
      const staff: StaffAttributes[] = data.staff_members.map((staff: any) => {
        const attributes = staff.attributes;
        attributes.user_roles = this.processUserRoles(attributes.user_roles);
        attributes.identity_document = this.processIdentityDocument(attributes.identity_document);
        attributes.visa = this.processVisa(attributes.visa);

        return attributes;
      });

      this.setState({
        data: staff,
        totalCount: data.total_count,
        totalPages: Math.ceil(data.total_count / 10),
        currentPage: data.current_page,
        nextPage: data.next_page,
        prevPage: data.prev_page,
      });
    }
  }

  processUserRoles(user_roles: any): Role[] {
    if (user_roles) {
      return user_roles
        .map((role: any) => {
          const foundRole = this.state.availableRoles.find((availableRole) => availableRole.name === role);
          return foundRole ? { id: foundRole.id, name: foundRole.name } : null;
        })
        .filter(Boolean) as Role[];
    }
    return [];
  }

  processIdentityDocument(identity_document: any): ReceivedFile[] {
    return identity_document ? [identity_document] : [];
  }

  processVisa(visa: any): ReceivedFile[] {
    return visa ? [visa] : [];
  }

  handleAddStaff(data: any) {
    if (data.errors) {
      this.setState({ errors: data.errors });
      return;
    }

    this.handleAddStaffClose();
    this.getStaffInformation();
    this.handleStaffCreatedOpen();
  }

  handleUpdateStaff(data: any) {
    if (data.errors) {
      this.setState({ errors: data.errors });
      return;
    }

    this.handleUpdateStaffClose();
    this.getStaffInformation();
  }

  handleLastStaffId(data: any, userDetail: any) {
    let restaurantId = userDetail?.restaurant?.id;

    if (!restaurantId) return;

    if (restaurantId.length !== 4) {
      restaurantId = restaurantId.toString().padStart(4, "0");
    }

    if (data.staff_id) {
      let newStaffId = (parseInt(data.staff_id.split("-")[1]) + 1).toString();

      if (newStaffId.length !== 4) {
        newStaffId = newStaffId.padStart(4, "0");
      }

      this.setState({ staffId: `${restaurantId}-${newStaffId}` });
    } else {
      this.setState({ staffId: `${restaurantId}-0001` });
    }
  }

  handleAvailableIdentity(data: any) {
    if (data.identity_types) {
      this.setState({ availableIdentityTypes: data.identity_types });
    }
  }

  handleAvailableRoles(data: any) {
    if (data.roles) {
      this.setState({ availableRoles: data.roles });
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const handler = this.getMessageHandler(callId, response);
    if (handler) handler();
    if (error) runEngine.debugLog("API Error", error);
  }

  getStaffInformation(page: number = 1) {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.staffInformationCallId = getDataMsg.messageId;
    const filters = this.state.roleFilter ? `&role_ids=${this.state.roleFilter}` : "";
    const search = this.state.search ? `&search=${this.state.search}` : "";
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `?page=${page}` + filters + search
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.staffInformationAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  getSchemas = (data: any, action: "add" | "update") => {
    return [
      { schema: StaffGeneralInfoSchema, tab: 0 },
      { schema: StaffAccountInfoSchema, tab: 1, condition: action === "add" },
      { schema: PermitSchema, tab: 0, condition: data.permitRequired },
    ];
  };

  validateSchema = (schema: any, data: any) => {
    const validation = schema.safeParse(data);
    const errorMap: Record<string, string> = {};

    if (!validation.success) {
      if (validation.error instanceof z.ZodError) {
        validation.error.errors.forEach((err: any) => {
          errorMap[err.path[0]] = err.message;
        });
        return { success: false, errorMap };
      }
    }
    return { success: true, errorMap };
  };

  validateData = (data: any, action: "add" | "update") => {
    const schemas = this.getSchemas(data, action);

    for (let { schema, tab, condition } of schemas) {
      if (condition === false) continue;
      const result = this.validateSchema(schema, data);
      if (!result.success) {
        this.setState({ validationErrors: result.errorMap });
        return { success: false, tab };
      }
    }

    return { success: true };
  };

  createFormData = (data: any, action: "add" | "update") => {
    const formData = new FormData();
    formData.append("account[staff_id]", action === "add" ? this.state.staffId : data.staffId);
    data.roles.forEach((role: number) => {
      formData.append("account[role_ids][]", role.toString());
    });
    if (data.permitRequired) {
      if (data.visaDocument.length > 0 && data.visaDocument[0] instanceof File)
        formData.append("account[visa]", data.visaDocument[0]);
      formData.append("account[expiry_date]", data.expiryDate);
    }
    formData.append("account[first_name]", data.firstName as string);
    formData.append("account[last_name]", data.lastName as string);
    formData.append("account[country_code]", data.countryCode as string);
    formData.append("account[phone_number]", data.phoneNumber.replace(data.countryCode, "") as string);
    formData.append("account[full_phone_number]", data.phoneNumber);
    formData.append("account[identity_type]", data.identityType as string);
    if (data.identityDocument.length > 0 && data.identityDocument[0] instanceof File)
      formData.append("account[identity_document]", data.identityDocument[0]);
    formData.append("account[permit_require]", JSON.stringify(data.permitRequired));
    formData.append("account[post_code]", data.postCode as string);
    formData.append("account[email]", data.email as string);
    formData.append("account[password]", data.password as string);

    return formData;
  };

  addStaff = (data: any) => {
    const validation = this.validateData(data, "add");
    if (!validation.success) {
      return validation;
    }

    const formData = this.createFormData(data, "add");

    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addStaffCallId = postDataMsg.messageId;
    postDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addStaffAPI.endPoint);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {
      token: this.state.token,
    });
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.addStaffAPI.method);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);

    return { success: true };
  };

  updateStaff = (data: any) => {
    const validation = this.validateData(data, "update");
    if (!validation.success) {
      return validation;
    }

    const formData = this.createFormData(data, "update");

    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateStaffCallId = postDataMsg.messageId;
    postDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateStaffAPI.endPoint + `/${this.state.selectedRow?.id}`
    );
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {
      token: this.state.token,
    });
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateStaffAPI.method);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);

    return { success: true };
  };

  getLastStaffId = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.lastStaffIdCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.lastStaffIdAPI.endPoint);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.lastStaffIdAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getAvailableIdentityTypes = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.identityOptionsCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.availableIdentityTypesAPI.endPoint
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.availableIdentityTypesAPI.method);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getAvailableRoles = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAvailableRolesCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.availableRolesAPI.endPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.availableRolesAPI.method);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getStaffByRole = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.staffByRoleCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  createMessage = (id: string, note: string): Message => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `/${id}` + `/add_note`
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ note_text: note }));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    return getDataMsg;
  };

  sendMessage = (message: Message) => runEngine.sendMessage(message.id, message);

  addNote = (id: string, note: string) => {
    const getDataMsg = this.createMessage(id, note);
    this.addNoteCallId = getDataMsg.messageId;
    this.sendMessage(getDataMsg);
  };

  handleUpdateStaffOpen = () => {
    this.getAvailableIdentityTypes();
    this.setState({ openUpdateStaff: true });
  };

  handleUpdateStaffClose = () =>
    this.setState({
      openUpdateStaff: false,
      errors: undefined,
      validationErrors: {},
    });

  handleAddStaffOpen = () => {
    this.getLastStaffId();
    this.getAvailableIdentityTypes();
    this.setState({ openAddStaff: true });
  };

  handleAddStaffClose = () =>
    this.setState({
      openAddStaff: false,
      errors: undefined,
      validationErrors: {},
    });

  confirmClose = () => this.setState({ openConfirmation: true });

  handleConfirmationClose = () => this.setState({ openConfirmation: false });

  discardChanges = () => {
    this.handleUpdateStaffClose();
    this.handleConfirmationClose();
  };

  handleManageNoteOpen = (id: string) => this.setState({ selectedId: id, openManageNote: true });

  handleManageNoteClose = () => this.setState({ selectedId: null, openManageNote: false });

  handleNoteSuccessClose = () => this.setState({ openNoteSuccess: false });

  handleStaffCreatedOpen = () => this.setState({ staffCreated: true });

  handleStaffCreatedClose = () => this.setState({ staffCreated: false });

  handleTabChange = (_event: React.SyntheticEvent, value: any) => this.setState({ selectedTab: value });

  handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) =>
    this.setState({ currentPage: page }, () => {
      this.getStaffInformation(page);
    });

  handleRoleFilterChange = (value: number) =>
    this.setState({ roleFilter: value }, () => {
      this.getStaffInformation(this.state.currentPage);
    });

  handleSelectedRow = (info: CellContext<StaffAttributes, any>) =>
    this.setState(
      {
        selectedRow: {
          ...info.row.original,
          identity_document: info.row.original.identity_document,
          visa: info.row.original.visa,
        },
      },
      () => {
        this.handleUpdateStaffOpen();
      }
    );

  handleViewDetails = (id: string) => this.props.navigation.navigate("ViewStaff", { id });

  handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.setState({ search: e.target.value }, () => {
      this.getStaffInformation(this.state.currentPage);
    });

  updateEvents = (events: Event[]) =>
    this.setState({ events });
}
