import React from "react";
import { Calendar, dateFnsLocalizer, Event, View } from 'react-big-calendar'
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import addHours from 'date-fns/addHours'
import startOfHour from 'date-fns/startOfHour'
import Toolbar from "./components/Toolbar";
import DateCellWrapper from "./components/DateCellWrapper";
import { Header } from "./components/Month";
import { EventComponent, EventContainerWrapper, EventWrapper } from "./components/EventComponent";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";

interface Props {
  error?: {
    message: string;
  };
  recievedView?: View;
  events: Event[];
  updateEvents: (events: Event[]) => void;
};

const WingosCalendar = React.memo(({ error, events, updateEvents, recievedView }: Props) => {
  const [view, setView] = React.useState<View>("month");

  const onEventResize: withDragAndDropProps['onEventResize'] = data => {
    const { start, end } = data;

    const newEvent = events.find(event => event.title === data.event.title);
    if (newEvent) {
      newEvent.start = new Date(start);
      newEvent.end = new Date(end);
    }

    if (newEvent) {
      const newEvents = events.map(event => {
        if (event.title === newEvent.title) {
          return newEvent;
        }
        return event;
      });
      updateEvents(newEvents);
    }
  }

  const onEventDrop: withDragAndDropProps['onEventDrop'] = data => {
    const { start, end } = data;

    const newEvent = events.find(event => event.title === data.event.title);
    if (newEvent) {
      newEvent.start = new Date(start);
      newEvent.end = new Date(end);
    }

    if (newEvent) {
      const newEvents = events.map(event => {
        if (event.title === newEvent.title) {
          return newEvent;
        }
        return event;
      });
      updateEvents(newEvents);
    }
  }

  React.useEffect(() => {
    if (recievedView) {
      setView(recievedView);
    }
  }, [recievedView]);

  return (
    <DnDCalendar
      resizable
      defaultView="month"
      view={view}
      onView={(view: View) => setView(view)}
      events={events}
      localizer={localizer}
      onEventDrop={onEventDrop}
      onEventResize={onEventResize}
      className="calendar__view"
      components={{
        toolbar: (props) => <Toolbar {...props} error={error} />,
        month: { header: Header },
        week: { header: Header },
        day: { header: Header },
        timeSlotWrapper: (props) => <div>{props.children}</div>,
        resourceHeader: (props) => <div>{props.children}</div>,
        dateCellWrapper: DateCellWrapper,
        event: EventComponent,
        eventWrapper: EventWrapper,
        eventContainerWrapper: EventContainerWrapper,
      }}
    />
  );
});

const locales = {
  'en-US': enUS,
}

const endOfHour = (date: Date): Date => addHours(startOfHour(date), 1)
const now = new Date()
const start = endOfHour(now)
const end = addHours(start, 2)

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})
//@ts-ignore
const DnDCalendar = withDragAndDrop(Calendar)

export default WingosCalendar;
