import React from "react";
import { IconButton, SvgIcon } from "@mui/material";
import "./ViewStaffHeader.css";

interface Props {
  icon: React.ReactNode;
  title: string;
  showEditButton?: boolean;
  editAction?: () => void;
}

const ViewStaffHeader: React.FC<Props> = ({ icon, title, showEditButton, editAction }) => {
  return (
    <div className="view_staff__personal_info__header">
      <div className="view_staff__personal_info__header__title">
        {icon}
        <span>{title}</span>
      </div>

      {showEditButton && (
        <IconButton onClick={editAction}>
          <SvgIcon>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m14.06 9.02.92.92L5.92 19H5v-.92zM17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29m-3.6 3.19L3 17.25V21h3.75L17.81 9.94z"
                fill="#E11B22"
              />
            </svg>
          </SvgIcon>
        </IconButton>
      )}
    </div>
  );
};

export default ViewStaffHeader;
