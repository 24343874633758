import React from "react";
import { ListItemIcon, ListItemText, MenuItem, MenuProps, Menu as MuiMenu } from "@mui/material";
import "./Menu.css";

type Option = {
    label: string;
    onClick?: () => void;
    icon?: React.ReactNode;
    hidden?: boolean;
}

interface Props extends MenuProps {
    options: Option[];
}

const Menu = (props: Props) => {
    const { options, ...rest } = props;
    return (
        <MuiMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{
                root: 'menu__root',
                paper: 'menu__paper',
                list: 'menu__list',
            }}
            {...rest}
        >
            {options.filter((option) => !option.hidden).map((option) => (
                <MenuItem data-testid="action-menu-item" key={option.label} onClick={option.onClick} classes={{ root: "menu__item" }} >
                    {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                    <span className="menu__item__text">{option.label}</span>
                </MenuItem>
            ))}
        </MuiMenu>
    );
}

export default Menu;
