import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {confirmationEmail} from "../../../components/src/EmailRegistrationAPI";
import { resendEmail, resendOtp } from "./../../../components/src/apiCall";
import { validatePhoneNumber } from "../../../components/src/utils";
export const configJSON1 = require("../../../framework/src/config.js");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  openTerms: boolean;
  isFormValid: boolean;
  passwordMatch: null | boolean;
  emailCorrect: null | boolean;
  signUpType: string;
  emailExists: boolean;
  numberExists: boolean;
  openPopup: boolean;
  inValidOtp: boolean;
  otp: string[];
  openOtpPopup: boolean;
  openSuccessPopup: boolean;
  smsToken: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  otpCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      openTerms: false,
      isFormValid: false,
      passwordMatch: null,
      emailCorrect: null,
      signUpType: "email_account",
      emailExists: false,
      numberExists: false,
      openPopup: false,
      inValidOtp: false,
      otp: [""],
      openOtpPopup: false,
      openSuccessPopup: false,
      smsToken: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{8,15}$/);
    this.emailReg = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestApiResponse(message);
    }
  
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigationPayload(message);
    }
  
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.handleCountryCode(message);
    }
  
    if (this.otpCallId === message.properties.RestAPIResponceDataMessage) {
      this.handleOtpApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const paramObj = this.props.navigation.getToken();
    if(paramObj.token) {
      await this.emailConfirmation(paramObj.token);
    }
  }

  emailConfirmation = async (token: string) => {
    const response = await confirmationEmail(token);
    if(response?.status === 200) {
      const {email, firstName, lastName} = response;
      this.setState({email: email, firstName: firstName, lastName: lastName, openSuccessPopup: true})
    }
  }

  handleRestApiResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.validationApiCallId) {
        this.handleValidationApiResponse(responseJson);
      } else if (apiRequestCallId === this.createAccountApiCallId) {
        this.handleCreateAccountApiResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  
  handleNavigationPayload(message: Message) {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }
  
  handleCountryCode(message: Message) {
    const selectedCode = message.getData(getName(MessageEnum.CountyCodeDataMessage));
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected: selectedCode.indexOf("+") > 0 ? selectedCode.split("+")[1] : selectedCode
      });
    }
  }
  
  handleOtpApiResponse(message: Message) {
    const apiRequestCallId = message.messageId;
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (apiRequestCallId && responseJson) {
      if (!responseJson.errors) {
        this.handleCreateAccountApiResponse(responseJson);
      } else {
        this.setState({ inValidOtp: true });
      }
    }
  }

  handleValidationApiResponse(responseJson: any) {
    if (responseJson.data && responseJson.data.length !== 0) {
      const regexData = responseJson.data[0];
      
      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }
  
      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }
  
      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleCreateAccountApiResponse(responseJson: any) {
    if(typeof responseJson === 'object' && responseJson.hasOwnProperty('status') && responseJson.status === 500) {
      return false
    } else if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );
  
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      if (msg.id === "AccoutResgistrationSuccess") {
        if(responseJson.data.type === 'sms_account') {
          this.setState({openOtpPopup: true, smsToken: responseJson.meta.token})
        } else if(responseJson.data.id === 'account_block/sms_otp' && responseJson.data.type === 'validate_available'){
          this.setState({openSuccessPopup: true})
        } else {
          this.setState({ openPopup: true });
        }
      }
    } else {
      this.handleCreateAccountError(responseJson.errors);
    }
  }

  private handleCreateAccountError(errors: any[]) {
    const error = errors[0];
    if (error?.id === 122) {
      this.setState({ emailExists: true });
    } else if(errors.hasOwnProperty('full_phone_number')) {
      this.setState({numberExists: true});
    } else if(error.account.includes("The number you entered is associated with another account")){
      this.setState({numberExists: true});
    }
     else {
      this.parseApiErrorResponse(errors);
    }
  }

  async resendEmailVerification() {
    await resendEmail(this.state.email);
  }

  async resendOtp() {
    await resendOtp(this.state.phone)
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  onAccountTypeChange = (type: string) => {
    this.setState({signUpType : type, email: '', countryCodeSelected: '', phone: '', isFormValid: false, emailCorrect: null, emailExists: false, numberExists: false});
  }

  handleInputChange = (fieldName: keyof S, value: string | boolean): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
      isFormValid: this.validateField(fieldName, value)
    };
    this.setState(updatedState as S);
    this.setState({passwordMatch: null, emailCorrect: null, numberExists: false})
    if(fieldName === 'email') {
      this.setState({emailExists: false});
    }
  }

  validateField = (fieldName: keyof S, value: string | boolean): boolean => {
    if(!value) {
      return false;
    }

    const fields: Set<keyof S> = new Set(['firstName', 'lastName', 'password', 'reTypePassword']);
    if(this.state.signUpType === 'sms_account') {
      fields.add('phone');
      fields.delete('email');
    } else {
      fields.delete('countryCodeSelected');
      fields.delete('phone');
      fields.add('email');
    }
    fields.delete(fieldName);
    for(let field of fields) {
      if (field === 'phone' && !validatePhoneNumber(this.state.phone, this.state.countryCodeSelected)) {
        return false;
      }
      if (!this.state[field]) {
        return false;
      }
    }
    return this.fieldSpecificValidity(fieldName, value);
  }

  fieldSpecificValidity = (fieldName: keyof S, value: string | boolean) : boolean => {
    if(fieldName === 'password' && (typeof value === "string") && (!this.passwordReg.test(value) || value.length < 8) ) {
      return false;
    }
    if(fieldName === 'phone' && !validatePhoneNumber(value as string, this.state.countryCodeSelected)) {
      return false;
    }
    return true;
  }


  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): void {
    if (this.state.email && !this.isValidEmail(this.state.email)) {
      this.setState({emailCorrect: false});
      return;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.setState({passwordMatch: false});
      return;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName.trim(),
      last_name: this.state.lastName.trim(),
      password: this.state.password.trim(),
      [`${this.state.signUpType === "email_account" ? "email" : "full_phone_number"}` ]: this.state.signUpType === "email_account" ? this.state.email.trim() : "+" + this.state.phone.trim()
    };

    

    const data = {
      type: this.state.signUpType,
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      email: this.state.email.trim(),
      password: this.state.password.trim(),
      reTypePassword: this.state.reTypePassword.trim(),
      phone: this.state.phone.trim()
    });
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  otpAuthentication=(otp:string):void=> {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      pin: otp,
      "token": this.state.smsToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.smsValidationApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isNonNullAndEmpty(value: string) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  closeEmailPopup=()=>{
    this.setState({openPopup:false})
  }

  closeOtpPopup=()=>{
    this.setState({openOtpPopup: false})
  }

  closeConfirmationPopup = () => {
    this.setState({openSuccessPopup: false});
    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
  }
  // Customizable Area End
}
