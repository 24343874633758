import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { locationChange } from '../../../blocks/storelocator/src/assets'
import "./ConfirmOrder.css"
import { CartItem } from '../../../blocks/catalogue/src/MenuPageController'

export interface RestaurantDataProps{
        restaurant_id:string ,order_type:string,title:string
}

interface Props{
restaurantData:RestaurantDataProps;
    closeRestaurantPopup:()=>void
    setRestaurantUsingNavPayoad:(id:number,order_type:string,token?:string)=>void
    unAvilableItems :{catalogue_name:string}[]
}

export default class RestaurantChangePopup extends Component<Props> {
    constructor(props:Props){
        super(props)
    }
    returnItemsToBeShown=(unAvilableItems:{catalogue_name:string}[])=>{
      const items = [unAvilableItems.map((value:{catalogue_name:string}) => value.catalogue_name)]
      const formattedItems = items.filter(item => item).join(' | ');
      const formattedWithPipe = formattedItems.replace(/,/g, ' | ');
      return formattedWithPipe
    }
  render() {
    return (
      <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"40px"}}>
        <img src={locationChange} alt='changing the restaurant'/>
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"24px" , fontFamily:"Barmeno"}}>
            <div style={{fontWeight:700, fontSize:"24px", textAlign:"center"}}>
            Location update! You will be switched to {this.props.restaurantData.title}.
            </div>
            <div style={{fontWeight:500, fontSize:"18px", color:"#747474", display:"flex", flexDirection:"column", alignItems:"center", gap:"12px"}}>
            <div>You are trying to switch to the new {this.props.restaurantData.title} store.</div>
            <div style={{textAlign:"center"}}>Would you like to proceed with new selection or stay on current selected {this.props.restaurantData.title} store?</div>
            {this.props.unAvilableItems.length>0 && <>
            <div>Some items in your cart aren't available at the selected store.</div>
            <div style={{color:"#E11B22"}}>*Unavailable items will be automatically removed from the cart</div>
            <div>The following items are unavailable at the moment:</div>
            <div style={{color:"#1D1D1D", textAlign:"center"}}>
            {
              this.returnItemsToBeShown(this.props.unAvilableItems)
               }
            </div>
            </>
            }
              </div>
        </div>
        <div style={{display:"flex", flexDirection:"row", gap:"40px", maxWidth:"520px", height:"56px", padding:"0px 24px", marginBottom:"20px"}}>
            <Button className="confirm_order_personalDetais_button" style={{color:"#E11B22", border:"2px solid #E11B22"}} onClick={this.props.closeRestaurantPopup}>STAY ON CURRENT</Button>
            <Button className="confirm_order_personalDetais_button" style={{color:"#FFFFFF", backgroundColor:"#E11B22"}} onClick={()=>{this.props.setRestaurantUsingNavPayoad(Number(this.props.restaurantData.restaurant_id),this.props.restaurantData.order_type,localStorage.getItem("authToken")||"");this.props.closeRestaurantPopup()}}>PROCEED WITH NEW</Button>
        </div>
      </div>
    )
  }
}
