import React from "react";
import { DialogProps, Dialog } from "@mui/material";
import "./Modal.css";
import CloseButton from "./CloseButton";

interface Props extends DialogProps {
  closeButton?: boolean;
}

const Modal = (props: Props) => {
  return (
    <Dialog
      fullWidth
      scroll="paper"
      maxWidth="md"
      classes={{
        root: "modal__wrapper",
        paper: "modal__container"
      }}
      {...props}
    >
      {props.closeButton && <CloseButton onClose={props.onClose} />}
      {props.children}
    </Dialog>
  );
}

export default Modal;
