import React from "react";

// Customizable Area Start
import { IconButton, Snackbar, Typography } from "@material-ui/core";
import ImageSeriesStatusOrderHistory from "../../../components/src/ImageSeriesStatusOrderHistory.web";
import {collectionHead, deliveryHead, home_icon, navigation_icon, avatar, call_icon } from "./assets"
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import { formatDateandTime } from "./../../../components/src/utils";
import Maps from "../../../blocks/maps/src/Maps.web";
import "./Task.web.css";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleCallAction = (contact: string) => {
    try {
      navigator.clipboard.writeText(contact.replace(/ /g, ''));
      this.setState({copied: true});
    } catch (error) {
      this.setState({failedToCopy: true});
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { imageGroupCollection, imageGroupDelivery, orderStatusesDelivery, orderStatusesCollection} = this.props;
    const { image, status } = this.onStatusImages(this.state.orderTracker.status);
    return (
      <>
      {console.log("orderorigin",this.state.orderTracker.delivery_address)}
      <div className="track_order">
        <Typography className="title">{`Track Order`}</Typography>
        <div className="id_time">
          <div className="track_id_on">
            <Typography className="id_code">{`${this.state.orderTracker.order_number}`}</Typography>
            <Typography className="date_time">{`Ordered on: ${formatDateandTime(this.state.orderTracker.placed_at)}`}</Typography>
          </div>
          <img className="scooter_icon" src={this.state.orderTracker.order_type === 'delivery' ? deliveryHead : collectionHead } alt="tracker_icon" />
        </div>
      </div>
      {this.props.orderStatus === 'in_transit' ? 
      <Maps navigation={this.props.navigation} id="maps" order_id={Number(this.props.id)} token={this.props.token} origin={{lat:this.state.orderTracker.restaurant.latitude, long:this.state.orderTracker.restaurant.longitude}} 
      destination={{lat:this.state.orderTracker.delivery_address.latitude, long:this.state.orderTracker.delivery_address.longitude}}/>
      : <img className="placed_icon" src={image} aria-details={status} alt="tracker_icon" />}
      <div className='order_status_container'>
      <div className='order_status_heading'>Order {this.props.orderStatus .replace('_', ' ')}</div>
      <div className='order_status_images'>
      <ImageSeriesStatusOrderHistory
          order_type={this.state.orderTracker.order_type || "delivery"} order_status={this.props.orderStatus} imageGroupDelivery={imageGroupDelivery} 
          imageGroupCollection={imageGroupCollection} deliveryStatuses={orderStatusesDelivery} 
          collectionStatuses={orderStatusesCollection}/>
      </div>
      </div>
      <div className="time_price">
        <div className='estimated_delivery_time'><AccessTimeRoundedIcon /> Estimated Time : <span style={{ color: "#2C6F37" }}>{this.props.estimatedTimeTosend}</span></div>
        <div>
          <Typography className="amount">Total Amount: <span style={{ color: "#1D1D1D" }}>£{this.state.orderTracker.total}</span></Typography>
        </div>
      </div>
        <div className="icons" style={{justifyContent: "space-between", alignItems: "center", gap: "4px", padding: "0 24px"}}>
          <div style={{display: "flex", alignItems: "center", gap: "8px", width: "100%"}}>
            <img src={home_icon} alt="home_icon" style={{ width: "24px", height: "24px"}} />
            <Typography className="pickup">
              <span style={{ color: 'rgb(255 0 9)'}}>{this.state.orderTracker.restaurant.title}</span> - {this.state.orderTracker.restaurant.location} {this.state.orderTracker.restaurant.contact}
            </Typography>

            <IconButton
              data-testId="call_button"
              disableRipple
              disableTouchRipple
              disableFocusRipple 
              onClick={() => this.handleCallAction(this.state.orderTracker.restaurant.contact)} 
              className="call_button"
            >
              <img src={call_icon} alt="call_icon" />
            </IconButton>

            
          </div>
          {this.state.orderTracker.order_type === 'delivery'?
          <>
          <div className="divider"></div>
          <div style={{display: "flex", gap: "8px", width: "100%", alignItems: "center"}}>
            <img src={navigation_icon} alt="navigation_icon" style={{ width: "24px", height: "24px"}} />
            <Typography className="home" style={{color: "#1D1D1D"}}>
              {this.state.orderTracker.delivery_address.address}, 
              {this.state.orderTracker.delivery_address.zip_code}</Typography>
          </div>
          </>:<></>}
        </div>
        {this.state.orderTracker.order_type === 'delivery'?
        <div className="rider_details">
          <img src={avatar} alt="avatar" />
          <Typography className="delivery_status">Your Delivery Rider will be assigned once the food is ready</Typography>
        </div> :<></>
  }

        <Snackbar
          data-testId="copied_snackbar"
          open={this.state.copied}
          autoHideDuration={2000}
          onClose={() => this.setState({copied: false})}
          message="Contact number copied to clipboard"
        />

        <Snackbar
          data-testId="failed_to_copy_snackbar"
          open={this.state.failedToCopy}
          autoHideDuration={2000}
          onClose={() => this.setState({failedToCopy: false})}
          message="Failed to copy contact number"
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
