import React from "react";
import Modal from "../../Modal";
import { OutlinedButton, ContainedButton } from "../../Button";
import "./ConfirmationPopup.css";

interface Props {
  open: boolean;
  onClose: () => void;
  goBack: () => void;
}

const ConfirmationPopup = (props: Props) => {
  return (
    <Modal
      maxWidth="xs"
      open={props.open}
      onClose={props.onClose}
    >
      <div className="confirm__wrapper">
        <div className="confirm__header">
          <span className="confirm__title">There are unsaved changes!</span>
          <p className="confirm__description">
            Are you sure you want to discard the changes?
          </p>
        </div>

        <div className="confirm__buttons">
          <OutlinedButton onClick={props.goBack}>Cancel</OutlinedButton>
          <ContainedButton onClick={props.onClose}>Discard</ContainedButton>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationPopup;
