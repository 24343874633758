import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert, Platform } from "react-native";
import { ChangeEventHandler } from "react";
import { getStorageData} from "../../../framework/src/Utilities";

export interface ItemDataInterface {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name": string,
    "description": string,
    "manufacture_date": string,
    "price": number,
    "loyalty_points": number,
    "catalogue_variants":
    {
      "id": string,
      "type": string,
      "attributes": {
        "id": number,
        "catalogue_id": number,
        "price": number | null,
      }
    }[],
  }
}

export interface FocusSubInterface { remove: () => void; }

//for web
export enum ViewTypes {
  SHOP = 'shop',
  REWARD = 'reward'
}

export interface ItemType {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name": string,
    "price": number,
    "catalogue_variants":
    {
      "id": string,
      "attributes": {
        "id": number,
        "price": number | null,
      }
    }[],
  }
}

export interface RewardType {
  "id": string,
  "type": string,
  "attributes": {
    "points": number,
    "price": number,
    "account_id": string,
    "status": string,
    "created_at": string,
    "title": string
  }
}

export interface ContentResponseData{
  id: string;
  type: string;
  attributes:{
      id: number;
      reward_section_type: string;
      title: string;
      description: string;
      image1:{
          url: string;
          file_name: string;
      };
      image2:{
          url: string;
          file_name: string;
      };
      image3:{
          url: string;
          file_name: string;
      }
  }
}

export interface RewardsPointsData{
  total_points: number;
  points_worth: number;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  itemData: ItemDataInterface[];
  selectedItems: number[];
  loader: boolean;
  //for web
  view: ViewTypes;
  errorMsg: string;
  successMsg: string;
  items: ItemType[];
  totalReward: number;
  rewards: RewardType[];
  open: boolean;
  redeemPoints: string;
  index: number;
  isLoggedIn: boolean;
  token : null| string;
  contentData: ContentResponseData[];
  rewardPoint: RewardsPointsData;
  rewardPointInput: number;
  rewardPointConverted: number;
  minRewardMessage: string;
  skeletonArray: number[];
  diableButton: boolean;
  minimumPoints: number;
  noItem : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoyaltySystemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitRequestCallId: string = "";
  buyRequestCallId: string = "";
  willFocusSubscription: FocusSubInterface = { remove: () => { } };
  //for web
  buyItemsApiCallId: string = "";
  itemListsApiCallId: string = "";
  rewardsApiCallId: string = "";
  redeemApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      itemData: [],
      selectedItems: [],
      loader: false,
      //for web
      view: ViewTypes.SHOP,
      errorMsg: "",
      successMsg: "",
      items: [],
      totalReward: 0,
      rewards: [],
      token: null,
      open: false,
      redeemPoints: '',
      index:0,
      isLoggedIn: false,
      contentData:[],
      rewardPoint:{total_points:0,points_worth:0},
      rewardPointInput: 0,
      rewardPointConverted: 0,
      minRewardMessage:'',
      skeletonArray: [1,2,3,4],
      diableButton:true,
      minimumPoints:1000,
      noItem: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.getTokenFun(token);
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.apiResponseCallback(message)
    }
  }
  // Customizable Area End

  // Customizable Area Start

  rewardContentApiCallId: string = "";
  rewardPointApiCallId: string = "";
  rewardPointWorthApiCallId: string = "";
  cartCallId:string="";

  async componentDidMount() {
    this.getToken()
    this.getRewardContent()
  }

  async componentWillUnmount() {
    this.willFocusSubscription.remove()
  }
  apiResponseCallback =(message:Message)=>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.rewardContentApiCallId) {
      this.catchRewardContent(responseJson)
    }
    if (apiRequestCallId === this.rewardPointApiCallId) {
      this.catchRewardPoint(responseJson)
    }
    if (apiRequestCallId === this.rewardPointWorthApiCallId) {
      this.catchRewardPointConversion(responseJson)
    }
    if(apiRequestCallId === this.cartCallId){
      this.catchCartData(responseJson)
    }
  }
  getTokenFun = (token: string) => {
    // this.apiResponseCallback(token)  
  }
  catchRewardContent =(responseJson:{data: ContentResponseData[]})=>{
    this.setState({contentData: responseJson.data})
  }

  catchCartData=(respopnseJson:any)=>{
    if(respopnseJson.data.length<1){
      this.setState({noItem:true})
    }else if(respopnseJson.data.length>=1){
      sessionStorage.setItem("redeemPoints",`${this.state.rewardPointInput}`)
      this.props.navigation.navigate('AddShoppingCartOrderItem')
    }
  }

  catchRewardPoint =(responseJson:{data?: RewardsPointsData,errors?:[{ "token": "Token has Expired" }],minimum_spend_points?:number})=>{
    if(responseJson.data && responseJson.minimum_spend_points){
    this.setState({rewardPoint: responseJson.data , minimumPoints:responseJson.minimum_spend_points})
    }else if(responseJson.errors && responseJson.errors[0].token){
      this.showAlert('token','expired')
    }
  }
  catchRewardPointConversion =(responseJson:{data?:{points_worth:number, message?:string} ,errors?:[{ "token": "Token has Expired" }]})=>{
    if(responseJson.data && !responseJson.data.message ){
      this.setState({rewardPointConverted: responseJson.data.points_worth, diableButton:false, minRewardMessage:""})
      }else if(responseJson.data && responseJson.data.message){
        this.setState({minRewardMessage: responseJson.data.message,rewardPointConverted:responseJson.data.points_worth,diableButton:true})
        }else{
          this.showAlert('token','expired')
      }
  }

  getRewardContent = ()=>{
    const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.rewardContentApiCallId = requestMessage.messageId
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContentRewardsEndPoint
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  callRewardPoints=(data:string|null)=>{
    if(data){
      this.getRewardPoints(data)
    }
  }
  getRewardPoints = (token:string)=>{
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token:token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.rewardPointApiCallId = requestMessage.messageId
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getWingoRewardsPointsEndPoint
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRewardPointsConversion = (rewardPointInput:number)=>{
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token:this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.rewardPointWorthApiCallId = requestMessage.messageId
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getWingoRewardsPointWorthEndPoint}?points=${rewardPointInput}`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goToLogin=()=> {
    const message: Message = new Message(
        getName(MessageEnum.NavigationEmailLogInMessage)
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }

  goToMenu=()=> {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMenuMessage)
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }

  getToken = ()=>{
     getStorageData('authToken',false).then(data => {
        this.setState({token: data, isLoggedIn: !!data})
        this.callRewardPoints(data)
        return data
      })
  }

  onChangeRewardPointInput :ChangeEventHandler<HTMLInputElement>=async(event)=>{
    const point_string = event.target.value.replace(/\D+/g, '')
    const point_number = Number(point_string)
    if(point_number>this.state.rewardPoint.total_points){
      this.setState({rewardPointInput:this.state.rewardPoint.total_points})
      this.getRewardPointsConversion(this.state.rewardPoint.total_points)
    }else{
    this.setState({rewardPointInput:point_number })
    this.getRewardPointsConversion(point_number)
    }
  }

  noItemInCart = ()=>{
    this.cartsList()
  }
  closeNoItemInCart =()=>{
    this.setState({noItem:false})
  }

  cartsList = async()=> {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cartCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cartsList
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
