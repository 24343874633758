export const configJSON = require("../../../../framework/src/config");
const baseURL = configJSON.baseURL.replace('https://', '');

export const connection = (token: string) => {
    const ws = new WebSocket(`wss://${baseURL}/cable?token=${token}`);
    return ws;
}

export const closeConnection = (ws: WebSocket) => {
    ws.onclose = () => {
        console.log('WebSocket connection closed');
    }
}