import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { DeliverInstruction } from '../../../blocks/shoppingcart/src/ShoppingCartOrdersController';
import CustomGreenCheckbox from '../CustomGreenCheckBox.web';

interface Props {
  deliveryInstructionList: DeliverInstruction[];
  changeDeliveryInstruction: (value: number) => void;
  deliveryInstruction: number[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
    },
    heading: {
      fontFamily: 'Barmeno, sans-serif',
      fontSize: '30px',
      fontWeight: 'bolder',
      lineHeight: '40px',
    },
    details: {
      flexDirection: 'column',
      gap: '12px'
    },
    entity: {
      display: 'flex',
      gap: '12px',
      alignItems: 'center'
    },
    value: {
      fontFamily: 'Barmeno, sans-serif',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '16px',
      color: '#747474'
    }
  }),
);

const DeliveryInstructionAccordian: React.FC<Props> = ({ deliveryInstructionList, changeDeliveryInstruction, deliveryInstruction }) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Instructions for Delivery Rider</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <RadioGroup
          data-testId="sides"
          row
          style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: "0px 12px" }}
          defaultValue={'on its own'}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          {deliveryInstructionList.map((value: DeliverInstruction) =>
            <FormControlLabel key={value.id}
              style={{ display: "flex", justifyContent: "space-between", margin: "0px", alignItems: "center" }}
              data-testId="spiceLevel"
              value={value.attributes.id}
              onChange={(e) => { changeDeliveryInstruction(value.attributes.id) }}
              control={<CustomGreenCheckbox checked={deliveryInstruction.includes(value.attributes.id)} />}
              labelPlacement="start"
              label={<div className='spiceLevel_label'><div style={{ display: "flex", gap: "8px", alignItems: "center" }}> <img style={{ width: "24px", height: "24px" }} src={value.attributes.images.url} alt={value.attributes.images.file_name} /> <div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 500 }} >{value.attributes.title}</div></div></div>} />
          )}
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  )
}

export default DeliveryInstructionAccordian