Object.defineProperty(exports, "__esModule", {
    value: true
});

exports.contentType = "application/json";
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.orderStatistics = {
    endPoint: "bx_block_dashboard/store_manage_dashboard/order_statics",
    method: "GET"
};
exports.orderRevenue = {
    endPoint: "bx_block_dashboard/store_manage_dashboard/order_revenue",
    method: "GET"
};
exports.printReceiptAPI = {
    method: "POST",
    endPoint: "bx_block_receiptcustomisation/print_receipts",
    contentType: "application/json"
};
exports.orderActionAPI = {
    endPoint: "bx_block_dashboard/store_manage_dashboard",
}

exports.orderAction = {
    accept: "order_accept_by_store",
    cancel: "order_cancel_by_store",
    skip: "skip_order_status"
}

exports.search = {
    endPoint: "bx_block_keyword_search/keyword_searches",
    method: "GET"
}
