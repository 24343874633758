import React from 'react'
import GooglePayController, {Props,configJSON} from './GooglePayController'
import GooglePayButton from "@google-pay/button-react"
import { Dialog, Modal } from '@material-ui/core'

export default class GooglePay extends GooglePayController {
    constructor(props:Props){
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <>
        <div style={{ padding: "40px", display: "none" }} ref={this.props.googlePayButtonRef}>
            <GooglePayButton
                environment={configJSON.paymentEnvironMent}
                paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                        {
                            type: configJSON.paymentType,
                            parameters: {
                                allowedAuthMethods: configJSON.allowedAuthMethods,
                                allowedCardNetworks: configJSON.allowedCardNetworks
                            },
                            tokenizationSpecification: {
                                type: configJSON.tokenizationType,
                                parameters: configJSON.tokenizationParameter
                            }
                        }],
                    merchantInfo: {
                        merchantId: configJSON.merchantId,
                        merchantName: configJSON.merchantName
                    },
                    transactionInfo: {
                        totalPriceStatus: configJSON.totalPriceStatus,
                        totalPriceLabel: configJSON.totalPriceLabel,
                        totalPrice: this.props.amount,
                        currencyCode: configJSON.currencyCode,
                        countryCode: configJSON.countryCode
                    },
                    shippingAddressRequired: false,
                    callbackIntents: configJSON.callbackIntents,
                }}
                onPaymentAuthorized={paymentData => {
                    this.setPaymentId(paymentData.paymentMethodData.tokenizationData.token)
                    return { transactionState: configJSON.transactionState }
                }}
                existingPaymentMethodRequired={false}
                buttonColor={configJSON.buttonColor}
                buttonType={configJSON.buttonType}
                buttonSizeMode={configJSON.buttonSizeMode}
            />
        </div>
        <div style={{ padding: "40px", display: "none" }} ref={this.props.cardPayButtonRef}>
        <button id="buttonTopay" onClick={this.doPaymentViaCard}>Trigger Card Pay</button>
        </div>
        <div style={{ padding: "40px", display: "none" }} ref={this.props.cashPayButtonRef}>
        <button id="buttonTopayCash" onClick={this.doPaymentViaCash}>Trigger Card Pay</button>
        </div>
        <Dialog open={this.state.openStripe}>
        <iframe src={this.state.location} />
        </Dialog>
        </>
    )
    // Customizable Area End
  }
}
