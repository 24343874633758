import React, { Component } from 'react';
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { success, failure } from "./../src/SocialLogin/assets";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface PaymentPopupProps {
  open: boolean;
  onClose: () => void;
  img: string;
  heading: string;
  content: string;
  button: string;
  tryAgain: () => void;
  attempts?: number;
}

interface S {
  primaryButton: boolean;
  secondaryButton: boolean;
}

const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: '600px !important',
        padding: "40px !important",
        borderRadius: "16px"
      },
    },
  }
});

export default class PaymentPopup extends Component<PaymentPopupProps, S> {
  constructor(props: PaymentPopupProps) {
    super(props);

    this.state = {
      primaryButton: true,
      secondaryButton: false
    };
  }

  renderCTA = (attempts: number) => {
    const PrimaryButton = ({ label, onClick }: { label: string; onClick: () => void; }) => <Button data-testId="onclose" onClick={onClick} style={{ flex: 1, width: '100%', height: '56px', color: '#fff', backgroundColor: '#E11B22', borderRadius: '8px' }}>{label}</Button>
    const SecondaryButton = ({ label, onClick }: { label: string; onClick: () => void; }) => <Button data-testId="changePayment" onClick={onClick} style={{ flex: 1, width: '100%', height: '56px', color: '#fff', borderRadius: '8px', backgroundColor: '#E11B22' }}>{label}</Button>
    
    switch (attempts) {
      case 1 || 2:
        return <PrimaryButton label={this.props.button} onClick={this.props.button === "TRY AGAIN" ? this.props.tryAgain : this.props.onClose} />;

      case 3:
        return (
          <>
            <PrimaryButton label={this.props.button} onClick={this.props.button === "TRY AGAIN" ? this.props.tryAgain : this.props.onClose} />
            <SecondaryButton
              label="CHANGE PAYMENT METHOD"
              onClick={this.props.onClose} />
          </>
        );

      case 4:
        return <PrimaryButton
          label="Back to Menu"
          onClick={() => {
            this.props.onClose();
            window.location.href = "/Menu";
          }} />;

      default:
        return <PrimaryButton label={this.props.button} onClick={this.props.button === "TRY AGAIN" ? this.props.tryAgain : this.props.onClose} />;
    }
  }

  renderContent = (attempts: number | undefined) => {
    const { content } = this.props;

    switch(attempts) {
      case 3:
        return "Please try again with a different payment method.";

      case 4:
        return "You have reached the maximum number of attempts. Please try again later.";

      default:
        return content;
    }
  }

  render() {
    const { open, img, heading, attempts } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Dialog open={open} maxWidth="md" >
          <DialogContent >
          </DialogContent>
          <Box textAlign="center" style={{ display: 'flex', flexDirection: "column", alignItems: 'center', gap: "40px" }}>
            <img src={img === 'success' ? success : failure} alt="success" style={{ width: "250px", height: "250px" }} />
            <div style={{ display: 'flex', flexDirection: "column", gap: '24px' }}><Typography style={{ fontFamily: 'Barmeno', fontSize: '24px', fontWeight: 700, textAlign: "center" }}>
              {heading}
            </Typography>
              <Typography style={{ fontFamily: 'Barmeno', fontSize: '18px', fontWeight: 500, textAlign: "center" }}>
                {this.renderContent(attempts)}
              </Typography></div>

            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", gap: "32px" }}>
              {this.renderCTA(attempts || 0)}
            </div>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
  }
}